import React, { Component } from 'react';
import { get } from 'axios';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import {Container} from 'bloomer';
import GroupBlock from "../components/GroupBlock";
import Trip from "./Trip";

class CounsellorGroups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            participants: null,
            counsellors: null,
            selectedCounsellor: 0,
        }
    }

    async componentDidMount() {
        let { id, counsellorId } = this.props.match.params;
        let { tripId, setTripIdAsync } = this.props;

        if(counsellorId) {
            this.setState({
                selectedCounsellor: counsellorId
            })
        }

        if(id !== tripId) setTripIdAsync(id);

        let options = {
            params: {
                tripId: id,
            }
        };

        let res = await get('/api/Trips/GetTripParticipants', options);

        let participants = await res.data;

        let counsellors = participants.map(participant => participant.counsellor).filter(Boolean);
        let uniqueCounsellors = [];
        let counsellorIds = new Set();

        counsellors.forEach(individual => {
            if(counsellorIds.has(individual.individualId)) return false;

            counsellorIds.add(individual.individualId);
            uniqueCounsellors.push(individual);
        });

        this.setState({
            participants: participants,
            counsellors: uniqueCounsellors,
        });
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    render() {
        let { participants, counsellors, selectedCounsellor } = this.state;
        let { id } = this.props.match.params;
        let { tripType } = this.props;

        if(!participants || ! counsellors) return null;

        let options = counsellors.map(individual => {
            return (
                <option key={individual.individualId} value={individual.individualId} onClick={this.onChange}>{individual.firstName} {individual.lastName}</option>
            )
        });
        
        return (
            <Container className="peopleOverview">
                <h3 className="sectionTitle">Counsellor Groups</h3>
                <select className="select counsellorSelect" name="selectedCounsellor" value={selectedCounsellor} onChange={this.onChange}>
                    <option value={0}> Not Set </option>
                    {options}
                </select>
                <br/>
                {
                    tripType !== 'Multi-activity' &&
                        <>
                            <span className="skiLegend">Ski</span>
                            <span className="boardLegend">Snowboard</span>
                        </>
                }
                <br/>
                <Container className="groupParticipants">
                    {participants.filter(participant => participant.counsellorId == selectedCounsellor).map(individual => (
                        <GroupBlock tripType={tripType} key={individual.participantDetailsSkiId} participant={individual} tripId={id} push={this.props.history.push} />
                    ))}
                </Container>
            </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(CounsellorGroups)