import React, { Component } from 'react';
import { Container } from 'reactstrap';
import {
    Navbar,
    NavbarBrand,
    Icon,
    NavbarItem,
} from 'bloomer';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";

class Layout extends Component {
    constructor(props) {
        super(props);
    }

    signOut = () => {
        let { logoutAsync , push } = this.props;

        logoutAsync().then(() => {
           push('/login')
        })
    };

    render() {
        let { children, user, userName, push } = this.props;
        let loggedIn = localStorage.getItem("loggedIn");

        return (
            <div>
                <Navbar>
                    <NavbarBrand>
                        <NavbarItem>
                            <Icon isSize="medium" className="fa fa-globe fa-2x" onClick={() => {push('/')}} />
                        </NavbarItem>
                        { loggedIn && <NavbarItem>
                            <small>Logged in as <b>{userName}</b></small>
                        </NavbarItem> }
                        { loggedIn && <NavbarItem>
                            <Icon isSize="medium" className="fa fa-sign-out fa-2x" onClick={this.signOut}/>
                        </NavbarItem> }

                    </NavbarBrand>
                </Navbar>
                <Container>
                    {children}
                </Container>
            </div>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(Layout);