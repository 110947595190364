import React, { Component } from 'react';
import { get } from 'axios';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import { Container } from 'bloomer';
import { staffTypes } from '../Types/staff'
import StaffBlock from "../components/StaffBlock";

class StaffList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            staffList: null,
        }
    }

    async componentDidMount() {
        let { id } = this.props.match.params;
        let { tripId, setTripIdAsync } = this.props;

        if(id !== tripId) setTripIdAsync(id);

        let options = {
            params: {
                tripId: id,
            }
        };

        let res = await get('/api/Trips/GetTripStaff', options);

        this.setState({
            staffList: res.data,
        });
    }

    render() {
        let { tripId } = this.props;
        let { staffList } = this.state;

        if(!staffList) return null;

        let staffRoles = [... new Set(staffList.map(staffMember => staffMember.staffRole.roleDesc))];

        return (
            <Container className="peopleOverview">
                {
                    staffList.map(staffMember => (
                        <StaffBlock staffMember={staffMember} tripId={tripId} key={staffMember.tripStaffAssignmentId} push={this.props.history.push} />
                    ))
                }
                <Container className="legend">
                    {
                        staffRoles.map((role, i) => (
                            <span key={`legend-${i}`}>
                                <strong>{staffTypes[role]}</strong> - {role},
                                <br/>
                                {/*{staffTypes[role]} {role}*/}
                            </span>
                        ))
                    }
                </Container>
            </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(StaffList);