export let participantTypes =  {
    'CHILD | STUDENT | <16': 'C<16',
    'CHILD | STUDENT | 16+': 'C16+',
    // 'CHILD | STUDENT | <17': 'C<17',
    'CHILD | OF CHAPERONE | PARTICIPATING': 'CCOP',
    // 'CHILD | STUDENT | <18': 'C<18',
    'ADULT | CHAPERONE OR OTHER | PAYING': 'ACOP',
    'CHILD | OF CHAPERONE | NON-PARTICIPATING': 'CCNP',
    'ADULT | BUS DRIVER': 'BD',
    'ADULT | CHAPERONE | FREE PLACE': 'ACFP',
};