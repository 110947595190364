import React, { Component } from 'react';
import { Container, Title } from 'bloomer';

class TripNotes extends Component {    
    render() {
        let { programme } = this.props;
        
        return (
            <Container className="tripNotes">
                <Title isSize={6}> Notes 1 </Title>
                {programme.notes}
                <Title isSize={6}> Notes 2 </Title>
                {programme.noteSummary}
                <Title isSize={6}> Notes 3 </Title>
                {programme.notes3}
                <Title isSize={6}> Notes 4 </Title>
                {programme.notes4}
            </Container>
        )
    }
}

export default TripNotes;