import React, { Component } from 'react';
import { Container, Icon } from "bloomer";
import nameReplace from "../Helpers/NameReplace";

const participantTypes = {
    'CHILD | STUDENT | 16+': 'C16+',
    'CHILD | STUDENT | <16': 'C<16',
    'ADULT | CHAPERONE OR OTHER | PAYING': 'ACOP',
    'CHILD | OF CHAPERONE | PARTICIPATING': 'CCP',
    'CHILD | OF CHAPERONE | NON-PARTICIPATING': 'CCNP',
    'ADULT | CHAPERONE | FREE PLACE': 'ACFP',
    'ADULT | BUS DRIVER': 'BD'
};

class ParticipantBlock extends Component {
    goToParticipant = (participantId) => {
        let { push } = this.props;

        push(`/participant/${participantId}`);
    };

    render() {
        let { participant } = this.props;

        let participantTypeStyle = {
            display: 'inline-block',
            overflow: 'hidden',
        };

        let participantNameStyle = {
            display: 'inline-block',
            maxWidth: '175px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        };

        return (
            <Container className='participantBlock' onClick={() => this.goToParticipant(participant.participantDetailsSkiId)}>
                <div className="participantType" style={participantTypeStyle}>{participantTypes[participant.type]}</div>
                <div className="participantName" style={participantNameStyle}><b>{nameReplace(participant.firstName)} {participant.familyName.toUpperCase()}</b></div>
                <div className="participantIcons is-pulled-right">
                    <Icon isSize="small" style={{visibility: participant.medicalInformation ? '' : 'hidden'}} isAlign="left" className='fad fa-medkit' />
                    <Icon isSize="small" style={{visibility: participant.dietaryInformation ? '' : 'hidden'}} className='fa fa-utensils' />
                </div>
            </Container>
        )
    }
}

export default ParticipantBlock;