import React, { Component } from 'react';
import { Table, Container } from 'bloomer';
import Collapsible from 'react-collapsible';
import { participantTypes } from "../Types/participants";
import ParticipantLegend from "./ParticipantLegend";
import { get } from 'axios';

class MultiActivitySummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            abilities: null,
        }
    }

    async componentDidMount() {
        let { tripId } = this.props;

        let options = {
            params: {
                tripId,
            }
        };

        let res = await get('/api/Trips/GetMultiActivitySummary', options);
        
        this.setState({
            abilities: await res.data,
        })
    }

    getRow = (type) => {
        let { abilities } = this.state;
        
        let ability = abilities.filter(participant => participant.ability.type === type);
        let abilityRange = {};
        
        for(let i = 1; i <= 4; i++) {
            let abilityLevel = ability.find(participant => participant.ability.mountainBikeAbility === i);
            
            abilityRange[i] = abilityLevel ? abilityLevel.count : 0;
        }
        
        return (
            <tr key={`row-${type}`}>
                <td>{participantTypes[type]}</td>
                <td>{abilityRange[1]}</td>
                <td>{abilityRange[2]}</td>
                <td>{abilityRange[3]}</td>
                <td>{abilityRange[4]}</td>
            </tr>
        )
    };
    
    getTotalRow = () => {
      let { abilities } = this.state;
      
      let abilityTotal = {};
      
      for (let i = 1; i <= 4; i++)
      {
          let abilityLevel = abilities.filter(ability => ability.ability.mountainBikeAbility === i);
          
          if(abilityLevel.length === 0) {
              abilityTotal[i] = 0;
          } else {
              abilityTotal[i] = abilityLevel.reduce((a,b) => a + (b.count), 0);
          }
      }
      
      return (
          <tr>
              <td>Total</td>
              <td>{abilityTotal[1]}</td>
              <td>{abilityTotal[2]}</td>
              <td>{abilityTotal[3]}</td>
              <td>{abilityTotal[4]}</td>
          </tr>
      )
    };
    
    render() {
        let { abilities } = this.state;
        
        if(!abilities) return null;
        
        let rows = Object.keys(participantTypes).map(type => {
            return this.getRow(type);
        });
        
        return (
            <Container>
                <Collapsible trigger="Mountain Bike Ability Summary">
                    <Table style={{fontSize: '0.8rem'}} isBordered isStriped>
                        <thead>
                            <tr>
                                <th>Participant Type</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                            </tr>
                        </thead>
                        <tbody>
                            { rows }
                            { this.getTotalRow() }
                        </tbody>
                    </Table>
                </Collapsible>
            </Container>
        )
    }
}

export default MultiActivitySummary