import React, { Component } from 'react';
import { Table, Container } from 'bloomer';
import { get } from 'axios';

export default class RoomBoardSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        let { tripId } = this.props;

        let options = {
            params: {
                tripId,
            }
        };

        let res = await get('/api/People/GetRoomBoardSummary', options);

        this.setState({
            summaryInfo: await res.data,
        })

    }

    render() {
        let { summaryInfo } = this.state;

        if(!summaryInfo) {
            return null;
        }

        return (
            <Container>
                <Table isStriped isNarrow isBordered style={{fontSize: '0.8rem'}}>
                    <tbody>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Adults</td>
                            <td>{summaryInfo.adults}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Children (-6)</td>
                            <td>{summaryInfo.childrenLessThan6}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Children (6-15)</td>
                            <td>{summaryInfo.childrenMoreThan6}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Total</td>
                            <td>
                                {summaryInfo.adults + summaryInfo.childrenLessThan6 + summaryInfo.childrenMoreThan6}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        )
    }
}