import React, { Component } from 'react';
import { get } from 'axios';
import { Table, Button, Container } from 'bloomer'
import downloadFile from "../Helpers/downloadFile";

class TripResources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: null,
            loading: true,
        }
    }

    async componentDidMount() {
        let { tripId } = this.props;

        let options = {
            params: {
                tripId
            }
        };

        let res = await get('/api/Trips/GetTripResources', options);
        let resources = await res.data;

        this.setState({
            resources,
            loading: false,
        })
    }

    downloadResource = (resourceId) => {
        let options = {
            params: {
                resourceId
            }
        };

        get('/api/Resource/DownloadResource', options).then(res => {
            let { fileData, fileName, mimeType } = res.data;
            
            downloadFile(fileData, fileName, mimeType);
        })
    };

    render() {
        let { resources, loading } = this.state;

        if(!resources) {
            return (
                <Container>
                    {loading ? 'Loading Resources' : 'Trip has no resources' }
                </Container>
            )
        }

        if(resources.length === 0) {
            return (
                <Container>
                    Trip has no resources
                </Container>
            )
        }

        let resourcesRow = resources.map(resource => {
            return (
                <tr key={resource.fileId}>
                    <td>{resource.description}</td>
                    <td><Button isSize="small" isColor="info" onClick={() => {this.downloadResource(resource.fileId)}}>Download</Button></td>
                </tr>
            )
        });

        return (
            <Container>
                <Table isNarrow isStriped style={{fontSize: '0.65rem'}}>
                    <thead>
                        <tr>
                            {/*<th>File Name</th>*/}
                            <th colSpan={2}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                    {resourcesRow}
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default TripResources;