import React, { Component } from 'react';
import { Route, Redirect} from 'react-router';
import {Switch, withRouter} from "react-router-dom";
import { bindActionCreators} from "redux";
import { connect } from 'react-redux';
import{ actionCreators } from "./store/Store";
import Layout from './components/Layout';
import PageLayout from './components/PageLayout';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css'
import Login from "./Page/Login";
import Trip from "./Page/Trip";
import PeopleInfo from "./Page/PeopleInfo";
import DietaryInfo from "./Page/DietaryInfo";
import Accommodation from "./Page/Accommodation";
import Transport from "./Page/Transport";
import Medical from "./Page/Medical";
import AllParticipants from "./Page/AllParticipants";
import Participant from "./Page/Participant";
import StaffList from "./Page/StaffList";
import CounsellorGroups from "./Page/CounsellorGroups";
import Home from "./Page/Home";

let AppRoute = withRouter(function({component: Component, layout: Layout, ...rest})  {
    return (
        <Route
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props}/>
                </Layout>
            )}
        />
    )
});

function PrivateAppRoute({component: Component, layout: Layout, loggedIn, page, push, ...rest}) {
    return(
        <Route
            {...rest}
            render={props =>
            loggedIn ? (
                <Layout push={push} page={page}>
                    <Component {...props} />
                </Layout>
            ) : (
                <Redirect to={{pathname: '/login', state: {from: props.location }
                }}
                />
            )}
        />
    );
}

class Router extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false,
        }
    }

    render() {
        var loggedIn = localStorage.getItem("loggedIn");

        let { push } = this.props.history;

        return (
            <Switch>
                <AppRoute exact path='/login' component={Login} layout={Layout} />

                <PrivateAppRoute path='/trip/:id' component={Trip}  loggedIn={loggedIn}
                                 layout={PageLayout} push={push} page="MobileTripInformation" />

                <PrivateAppRoute path='/people/:id' component={PeopleInfo} loggedIn={loggedIn}
                                 layout={PageLayout} push={push} page="MobilePeopleInformation" />

                <PrivateAppRoute path='/dietary/:id/' component={DietaryInfo} loggedIn={loggedIn}
                                 layout={PageLayout} push={push} page="MobileDietaryInformation" />

                <PrivateAppRoute path='/accommodation/:id' component={Accommodation} loggedIn={loggedIn}
                                 layout={PageLayout} push={push} page="MobileAccomInformation" />

                <PrivateAppRoute path='/transport/:id' component={Transport} loggedIn={loggedIn}
                                 layout={PageLayout} push={push} page="MobileTransportInformation" />

                <PrivateAppRoute path='/medical/:id' component={Medical} loggedIn={loggedIn}
                                 layout={PageLayout} push={push} page="MobileMedicalInformation" />

                <PrivateAppRoute path='/participants/:id' component={AllParticipants} loggedIn={loggedIn}
                                 layout={PageLayout} push={push} page="MobilePeopleInformation" />

                <PrivateAppRoute path='/participant/:id' component={Participant} loggedIn={loggedIn}
                                 layout={PageLayout} push={push} page="MobilePeopleInformation" />

                <PrivateAppRoute path='/staffList/:id' component={StaffList} loggedIn={loggedIn}
                                 layout={PageLayout} push={push} page="MobilePeopleInformation" />

                <PrivateAppRoute path='/groups/:id/:counsellorId?' component={CounsellorGroups} loggedIn={loggedIn}
                                 layout={PageLayout} push={push} page="MobilePeopleInformation" />

                <PrivateAppRoute exact path='/' component={Home} loggedIn={loggedIn} layout={Layout} push={push} />
            </Switch>
        )

    }
}

// export default () => (
//   <Layout>
//       <Route exact path='/' component={Home} />
//       <Route exact path='/login' component={Login} />
//   </Layout>
// );


export default withRouter(connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(Router));
