import React, { Component } from 'react';
import { Container, Table } from 'bloomer';
import moment from 'moment';
import { get } from 'axios';

class TripActivities extends Component {
    constructor(props){
        super(props);

        this.state = {
            activities: null,
            loading: true
        }
    }

    async componentDidMount() {
        let { tripId } = this.props;

        let options = {
            params: {
                tripId
            }
        };

        get('/api/Trips/GetTripActivities', options).then(res => {
            this.setState({
                activities: res.data,
                loading: false
            })
        })
    }

    render() {
        let { activities, loading } = this.state;

        if(!activities) {
            return (
                <Container>
                    {loading ? "Loading" : "Trip has no activities" }
                </Container>
            )
        }

        let activityRows = activities.map(activity => {
            return (
                <tr key={activity.tripProgrammeId}>
                    <td>{activity.activity.activityName}</td>
                    <td>{moment(activity.activityDate).format("DD MMM YYYY")}</td>
                    <td>{activity.notes}</td>
                </tr>
            )
        });

        return (
            <Container>
                <Table style={{fontSize: '0.7rem'}}>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                    {activityRows}
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default TripActivities;