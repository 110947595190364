import React, { Component } from 'react';
import { Table, Container } from 'bloomer';
import { get } from 'axios';
import { participantTypes } from "../Types/participants";
import Collapsible from 'react-collapsible';
import ParticipantLegend from "./ParticipantLegend";

class SkiAbility extends Component {
    constructor(props) {
        super(props);

        this.state = {
            abilities: null,
        }
    }

    async componentDidMount() {
        let { tripId } = this.props;

        let options = {
            params: {
                tripId
            }
        };

        let res = await get('/api/Trips/GetSkiAbility', options);

        this.setState({
            abilities: await res.data,
        });
    }

    getRow = (type, skiBoard) => {
        let { abilities } = this.state;
        
        let ability = abilities.filter(participant => participant.ability.skiBoard === skiBoard && participant.ability.type === type);
        
        let abilityRange = {};

        for(let i = 1; i <= 4; i++) {
            let abilityLevel = ability.find(participant => participant.ability.ability === i);

            abilityRange[i] = abilityLevel ? abilityLevel.count : 0;
        }

        return (
            <tr key={`row-${skiBoard}-${type}`}>
                <td>{participantTypes[type]}</td>
                <td>{abilityRange[1]}</td>
                <td>{abilityRange[2]}</td>
                <td>{abilityRange[3]}</td>
                <td>{abilityRange[4]}</td>
            </tr>
        )

    };

    getTotalRow = (skiBoard) => {
        let { abilities } = this.state;

        let abilityTotal = {};

        for (let i = 1; i <= 4; i++ )
        {
            let abilityLevel = abilities.filter(ability => ability.ability.ability === i && ability.ability.skiBoard === skiBoard);

            if(abilityLevel.length === 0) {
                abilityTotal[i] = 0;
            } else {
                abilityTotal[i] = abilityLevel.reduce((a,b) => a + (b.count), 0);
            }
        }

        return (
            <tr>
                <td>Total</td>
                <td>{abilityTotal[1]}</td>
                <td>{abilityTotal[2]}</td>
                <td>{abilityTotal[3]}</td>
                <td>{abilityTotal[4]}</td>
            </tr>
        )
    };

    render() {
        let { abilities } = this.state;

        if(!abilities) {
            return null;
        }

        let skiRows = Object.keys(participantTypes).map(type => {
            return this.getRow(type, 'SKI');
        });
        
        let boardRows = Object.keys(participantTypes).map(type => {
            return this.getRow(type, 'BOARD')
        });
        
        return (
            <Container>
                <Collapsible trigger="Ski Ability Summary">
                    <Table style={{fontSize: '0.8rem'}} isBordered isStriped>
                        <thead>
                            <tr>
                                <th>Participant Type</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                            </tr>
                        </thead>
                        <tbody>
                            { skiRows }
                            {this.getTotalRow('SKI')}
                        </tbody>
                    </Table>
                    <ParticipantLegend />
                </Collapsible>
                <Collapsible trigger="Snowboard Ability Summary">
                    <Table isBordered isStriped style={{fontSize: '0.8rem'}}>
                        <thead>
                            <tr>
                                <th>Participant Type</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                            </tr>
                        </thead>
                        <tbody>
                            { boardRows }
                            {this.getTotalRow('BOARD')}
                        </tbody>
                    </Table>
                    <ParticipantLegend />
                </Collapsible>
        </Container>
        )
    }
}

export default SkiAbility