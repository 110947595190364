import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import { Container } from 'bloomer'
import axios, { get }   from 'axios';
import moment from 'moment';
import MealsInfo from "../components/MealsInfo";
import DietaryBlock from "../components/DietaryBlock";

class DietaryInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mealRequirements: null,
            participantInfo: [],
        }
    }

    async componentDidMount() {
        let {id: tripId} = this.props.match.params;

        let { setTripIdAsync } = this.props;

        setTripIdAsync(tripId);

        let options = {
            params: {
                tripId,
            }
        };

        let mealRes = get('/api/Meals/GetTripMealRequirements', options);
        let participantRes = get('/api/Meals/GetDietaryInfo', options);
        let staffRes = get('/api/Meals/GetStaffDietaryInfo', options);
        
        axios.all([mealRes, participantRes, staffRes]).then(axios.spread((...responses) => {
            this.setState({
                mealRequirements: responses[0].data,
                participantInfo: responses[1].data,
                staffInfo: responses[2].data,
            })
        }));
    }

    render() {
        let { id } = this.props.match.params;
        let { startDate, tripType } = this.props;
        let { mealRequirements, participantInfo, staffInfo} = this.state;

        if(!startDate || !mealRequirements ) return null;

        return (
           <Container className="dietaryOverview">
               <h3 className="sectionTitle">Meals</h3>
               <Container className="mealRequirements">
                   {/* In the database, Raclette and Fondue are given dates, Halal and Kosher are given type
                       which are strings. Not entirely sure why but this was the pre-existing structure they've
                       been using.
                   */}
                   <p>Raclette: <strong>{mealRequirements.raclette ? moment(mealRequirements.racletteDate).format('DD MMM YY') : 'N'}</strong></p>
                   <p>Fondue: <strong>{mealRequirements.fondue ? moment(mealRequirements.fondueDate).format('DD MMM YY') : 'N'}</strong></p>
                   <p>Halal: <strong>{mealRequirements.halalOption.mealOption1}</strong></p>
                   <p>Kosher: <strong>{mealRequirements.kosherOption.mealOption1}</strong></p>
                   <h3 className="titleBetweenSection">Notes</h3>
                   {mealRequirements.notes}
               </Container>
               <Container className='dietaryTable'>
                   <MealsInfo startDate={startDate} tripId={id}/>
               </Container>
               {tripType !== 'Room & Board' &&
                   <>
                       <h3 className="titleBetweenSection">Participants Dietary Information</h3>
                       <Container className="dietaryInfo">
                           {participantInfo.map(participant => (
                               <DietaryBlock key={participant.id} info={participant}/>
                           ))}
                       </Container>
                       {staffInfo.length > 0 &&
                       <Container className="staffInfo">
                           <h3 className="titleBetweenSection">Staff Dietary Information</h3>
                           {staffInfo.map((staff, i) => (
                               <Container key={`staff-${i}`} className="dietaryBlock">
                                   <Container className="blockHeader">
                                       <b>{staff.firstName} {staff.lastName}</b>
                                   </Container>
                                   {staff.dietaryNotes}
                               </Container>
                           ))}
                       </Container>
                       }
                   </>
               }
           </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(DietaryInfo);