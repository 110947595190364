import React, { Component }  from 'react';
import{ Navbar, Container } from "bloomer";
import { ReactComponent as AccommodationIcon } from '../Icons/accommodation.svg';
import { ReactComponent as DietaryIcon } from '../Icons/food.svg';
import { ReactComponent as MedicalIcon } from '../Icons/health.svg';
import { ReactComponent as PeopleIcon } from '../Icons/user.svg';
import { ReactComponent as TransportIcon } from '../Icons/transportation.svg'
import { ReactComponent as TripIcon } from '../Icons/list.svg'


class TripMenu extends Component {
    goToPage = (page) => {
        let { tripId, push } = this.props;

        push(`/${page}/${tripId}`);
    };

    render() {
        let { page, roles, tripType } = this.props;

        // Doing this in render didn't work for pages other than the trip page for some reason, think it has to do with the trip page
        // re-rendering need to check when I work on other pages.
        let isAccomm = page === 'MobileAccomInformation';
        let isDietary = page === 'MobileDietaryInformation';
        let isPeople = page === 'MobilePeopleInformation';
        let isMedical = page === 'MobileMedicalInformation';
        let isTransport = page === 'MobileTransportInformation';

        return (
            <Container className="tabMenuWrap">
                {roles.includes('MobileTripInformation') && (
                    <div className={page === 'MobileTripInformation' ? 'tabMenu tripIcon active' : 'tabMenu tripIcon'}>
                        <TripIcon height="35px" onClick={() => {this.goToPage('trip')}} fill="white"/>
                    </div>
                )}

                {roles.includes('MobilePeopleInformation') && (
                    <div className={isPeople ? 'tabMenu peopleIcon active' : 'tabMenu peopleIcon'}>
                        <PeopleIcon fill="white" height="35px" onClick={() => this.goToPage('people')} />
                    </div>
                )}

                {(roles.includes('MobileMedicalInformation') && tripType !== "Room & Board") && (
                    <div className={isMedical ? 'tabMenu medicalIcon active' : 'tabMenu medicalIcon'}>
                        <MedicalIcon fill="white" height="35px" onClick={() => this.goToPage('medical')} />
                    </div>
                )}

                {roles.includes('MobileTransportInformation') && (
                    <div className={isTransport ? 'tabMenu transportIcon active' : 'tabMenu transportIcon'}>
                        <TransportIcon fill="white" height="35px" onClick={() => this.goToPage('transport')} />
                    </div>
                )}

                {roles.includes('MobileAccomInformation') && (
                    <div className={isAccomm ? 'tabMenu accommIcon active' : 'tabMenu accommIcon'}>
                        <AccommodationIcon fill="white" height="35px" onClick={() => this.goToPage('accommodation')} />
                    </div>
                )}

                {roles.includes('MobileDietaryInformation') && (
                    <div className={isDietary ? 'tabMenu dietIcon active' : 'tabMenu dietIcon'}>
                        <DietaryIcon fill="white" height="35px" onClick={() => {this.goToPage('dietary')}} />
                    </div>
                )}
            </Container>
        )
    }
}

export default TripMenu;