import React, { Component } from 'react';
import moment from 'moment';
import {Content} from "bloomer";
import { TripStatus } from "../Types/tripStatus";
import { ReactComponent as ConfirmedReady } from '../Icons/confirmed-ready.svg';

class TripBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        const { booker, resorts, dateFrom, dateTo, nights, notes, gotoTrip, type, statusId } = this.props;

        console.log(type);
        
        let resortInfo = resorts.map((resort, i) => {
            return(
                <span key={resort.resortAccommodationId}>
                    {resort.resort}, {resort.accommodation}
                    {i < resorts.length - 1 ? ' | ' : ''}
                </span>
            )
        });
        
        let tripStatus = TripStatus[statusId];
        
        return (
            <Content className={`tripBlock ${type.replace(/[^A-Z0-9]+/ig, "_").toLowerCase()}`} onClick={gotoTrip}>
                <div className="tripBlockContent">
                    <h3>{booker} {notes ? `| ${notes}` : ''}</h3>
                    <p>{moment(dateFrom).format("DD MMM YYYY")} - {moment(dateTo).format("DD MMM YYYY")}, <span className="nights">{nights > 1 ? `${nights} nights` : `1 night`}</span></p>
                    <p className="place"> {resortInfo} </p>
                    {tripStatus === 'Ready' &&
                        <span className="readyIconHolder">
                            <ConfirmedReady className="readyIcon"/>
                        </span>
                    }
                </div>
            </Content>
        )
    }
}

export default TripBlock;