import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {actionCreators} from "../store/Store";
import {
    Button,
    Container,
    Control,
    Field,
    Label,
    Modal,
    ModalClose,
    ModalContent
} from "bloomer";
import Select from 'react-select';
import moment from 'moment';

import 'bulma-calendar/dist/css/bulma-calendar.min.css';
import 'bulma-calendar/dist/js/bulma-calendar.min';
import {SingleDatePicker} from "react-dates/esm";
import {OPEN_UP} from "react-dates/constants"

class TripSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateFrom: null,
            dateTo: null,
            focusedStart: null,
            focusedEnd: null,
        }
    }

    renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
                <select
                    value={month.month()}
                    onChange={(e) => onMonthSelect(month, e.target.value)}
                >
                    {moment.months().map((label, value) => (
                        <option key={`month-${value}`} value={value}>{label}</option>
                    ))}
                </select>
            </div>
            <div>
                <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                    {
                        [...Array(10).keys()].map(i => (
                            <option key={i} value={moment().year() + (i - 5)}>
                                {moment().year() + (i - 5)}
                            </option>
                        ))
                    }
                </select>
            </div>
        </div>;

    async componentDidMount() {
        let { tripFrom, tripTo,
            clientList, getClientsAsync,
            contactList, getContactsAsync,
            resortList, getResortsAsync,
            accommodationList, getAccommodationAsync
        } = this.props;

        if(!accommodationList) {
            getAccommodationAsync();
        }

        if(!resortList) {
            getResortsAsync();
        }

        if(!clientList) {
            getClientsAsync();
        }

        if(!contactList) {
            getContactsAsync();
        }

        this.setState({
            dateFrom: tripFrom,
            dateTo: tripTo,
        })
    }

    // clearSearch = () => {
    //     // TODO: Clear Search here
    //     let { selectClient, selectAccommodation, selectResort, toggleModal } = this.props;
    //
    //     selectClient(null);
    //     selectAccommodation(null);
    //     selectResort(null);
    //     toggleModal();
    // }

    performSearch = () => {
        let { setTripDateAsync, toggleModal } = this.props;
        let { dateFrom, dateTo } = this.state;
    
        setTripDateAsync(dateFrom, dateTo);
        toggleModal();
    };

    onClientSelect = (selectedOption) => {
        let { selectClient } = this.props;

        selectedOption ? selectClient(selectedOption.value) : selectClient(null);
    };

    onAccommodationSelect = (selectedOption) => {
        let { selectAccommodation } = this.props;

        selectedOption ? selectAccommodation(selectedOption.value) : selectAccommodation(null);
    };

    onContactSelect = (selectedOption) => {
        let { selectContact } = this.props;

        selectedOption ? selectContact(selectedOption.value) : selectContact(null);
    };

    onResortSelect = (selectedOption) => {
        let { selectResort } = this.props;

        selectedOption ? selectResort(selectedOption.value) : selectResort(null);
    };

    clearSearch = () => {
        let { toggleModal, clearSearch, getWeekTrips } = this.props;

        clearSearch();
        this.setState({
            dateFrom: null,
            dateTo: null,
        });
        getWeekTrips(moment());
        toggleModal();
    };

    render() {
        let { dateFrom , dateTo, focusedStart, focusedEnd    } = this.state;
        let { searchOpen, toggleModal,
            clientList, selectedClientId,
            contactList, selectedContactId,
            resortList, selectedResortId,
            accommodationList, selectedAccommodationId} = this.props;

        if(!clientList || !contactList || !resortList || !accommodationList) return null;

        return (
            <Modal isActive={searchOpen} className='searchModal'>
                <ModalContent>
                    {/* Needs a margin-top so that the date calendar doesn't get cut off */}
                    <Container style={{marginTop: '35px'}}>
                        <h3 className='sectionTitle'>Search Trips</h3>
                        <Field>
                            <Label>Client</Label>
                            <Control>
                                <Select
                                    onChange={this.onClientSelect}
                                    options={clientList}
                                    value={selectedClientId ? clientList.find(clientList => clientList.value === selectedClientId) : null}
                                    isClearable={true}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Label>Contact</Label>
                            <Control>
                                <Select
                                    onChange={this.onContactSelect}
                                    options={contactList}
                                    value={selectedContactId ? contactList.find(contactList => contactList.value === selectedContactId) : null}
                                    isClearable={true}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Label>Resort</Label>
                            <Control>
                                <Select
                                    onChange={this.onResortSelect}
                                    options={resortList}
                                    value={selectedResortId ? resortList.find(resortList => resortList.value === selectedResortId) : null}
                                    isClearable={true}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Label>Accommodation</Label>
                            <Control>
                                <Select
                                    onChange={this.onAccommodationSelect}
                                    options={accommodationList}
                                    value={selectedAccommodationId ? accommodationList.find(accommodationList => accommodationList.value === selectedAccommodationId) : null}
                                    isClearable={true}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Label>From</Label>
                            <SingleDatePicker
                                date={dateFrom ? dateFrom : null}
                                onDateChange={date => this.setState({dateFrom: date})}
                                focused={focusedStart}
                                placeholder="DD MMM YYYY"
                                onFocusChange={focusedInput => {this.setState({focusedStart: focusedInput.focused})}}
                                id="start_date_picker"
                                block
                                openDirection={OPEN_UP}
                                keepOpenOnDateSelect={false}
                                numberOfMonths={1}
                                isOutsideRange={() => false}
                                renderMonthElement={this.renderMonthElement}
                                displayFormat="DD MMM YYYY"
                                daySize={35}
                                showClearDate
                            />
                        </Field>
                        <Field>
                            <Label>To</Label>
                            <SingleDatePicker
                                date={dateTo}
                                onDateChange={date => this.setState({dateTo: date})}
                                focused={focusedEnd}
                                onFocusChange={focusedInput => {this.setState({focusedEnd: focusedInput.focused})}}
                                id="end_date_picker"
                                block
                                placeholder="DD MMM YYYY"
                                openDirection={OPEN_UP}
                                numberOfMonths={1}
                                isOutsideRange={() => false}
                                renderMonthElement={this.renderMonthElement}
                                displayFormat="DD MMM YYYY"
                                daySize={35}
                                showClearDate
                            />
                        </Field>
                        <Field isGrouped>
                            <Control>
                                <Button className="searchButton" onClick={this.performSearch} isColor="info">Search</Button>
                            </Control>
                            <Control>
                                <Button className="cancelSearch" onClick={this.clearSearch}> Clear Search </Button>
                            </Control>
                        </Field>
                    </Container>
                </ModalContent>
                <ModalClose style={{backgroundColor: 'black'}} className='tripSearchModalClose' onClick={toggleModal}/>
            </Modal>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(TripSearch);