import React, { Component } from 'react';

const participantTypes = {
    childLessThan16: 'Child | Student | <16',
    child16Plus: 'Child | Student | 16+',
    CCP: 'Child | of Chaperone | Participating',
    CCNP: 'Child | of Chaperone | non-Participating',
    ACFP: 'Adult | Chaperone | Free Place',
    ACOP: 'Adult | Chaperone or Other | Paying',
    BD: 'Adult | Bus driver'
};


export default class ParticipantLegend extends Component {
    render() {
        return (
            <div className="legend">
               { Object.keys(participantTypes).map((n,i) => {
                    let key = n;

                    if(n === 'childLessThan16') key = 'C<16';
                    if(n === 'child16Plus') key = 'C16+';

                    return(
                        <span key={`legend-${i}`}>
                            <strong>{key}</strong>  - {participantTypes[n]},
                            <br/>
                        </span>
                    )
               })}
            </div>
        )
    }
}