import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import { Container, Table } from "bloomer";
import { get } from 'axios';
import moment from 'moment';
import Collapsible from 'react-collapsible';
import Excursions from "../components/Excursions";
import Arrival from "../components/Arrival";
import Departure from "../components/Departure";

class Transport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trips: [],
        }
    }

    async componentDidMount() {
        let {id: tripId} = this.props.match.params;

        let { setTripIdAsync } = this.props;

        setTripIdAsync(tripId);

        let options = {
            params: {
                tripId,
            }
        };

        let res = await get('/api/Trips/GetTripTransports', options);

        this.setState({
            trips: await res.data,
        })
    }

    render() {
        let { trips } = this.state;
        let { id } = this.props.match.params;

        if(trips.length <= 0){
            return (
                <Container className="transportOverview">
                    No transport defined for this trip.
                </Container>
            )
        }

        let arrivals = trips.filter(trip => trip.journeyType === 'Arrival');
        let departures = trips.filter(trip => trip.journeyType === 'Departure');

        return (
            <Container className="transportOverview">
                <h3 className="sectionTitle">
                    Transport
                </h3>
                <Container className="arrivalDepartureBlock">
                    <h3 className="titleBetweenSection noBorder">Transfers</h3>
                    <Container className="arrivalInfo">
                        <Collapsible trigger="Arrivals">
                            { arrivals  && arrivals.map((arrival, i) => (
                                <span key={`arrival-${i}`}>
                                    <Arrival arrival={arrival.journeyLegs[0]} />
                                    { i < arrivals.length - 1 && <br/> }
                                </span>
                            ))}
                        </Collapsible>
                        <Collapsible trigger="Departure">
                            { departures && departures.map((departure, i) => (
                                <span key={`arrival-${i}`}>
                                    <Departure  departure={departure.journeyLegs[0]} />
                                    { i < departures.length - 1 && <br/> }
                                </span>
                            ))}
                        </Collapsible>
                    </Container>
                    <Container className="excursionInfo">
                        <h3 className="titleBetweenSection">Excursions</h3>
                        <Excursions tripId={id} />
                    </Container>
                </Container>
            </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(Transport);