import React, { Component } from 'react';
import { get } from 'axios';
import { Table, Container } from 'bloomer';

class InsuranceContacts extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            insurancePolicies: null,
            emergencyContacts: null,
        }
    }
    
    async componentDidMount() {
        let { tripId } = this.props;
        
        let options = {
            params: {
                tripId
            }
        };
        
        let insuranceRes = await get('/api/Trips/GetInsuranceInfo', options);
        let insuranceInfo = await insuranceRes.data;
        
        let emergencyRes = await get('/api/Trips/GetEmergencyContacts', options);
        let emergencyInfo = await emergencyRes.data;
        
        this.setState({
            insurancePolicies: insuranceInfo,
            emergencyContacts: emergencyInfo
        });
    }
    
    render() {
        let { insurancePolicies, emergencyContacts } = this.state;
        let insuranceRows = null;
        
        if(insurancePolicies) {
            insuranceRows = insurancePolicies.map((n, i) => {
                return (
                    <tr key={`insurance-${i}`}>
                        <td>{n.type}</td>
                        <td>{n.insurerName}</td>
                        <td>{n.policyName}</td>
                        <td>{n.policyNumber}</td>
                    </tr>
                )
            })
        }
        
        return (
            <div>
                <Table style={{fontSize: '0.7rem'}}>
                    <thead>
                        <tr>
                            <th> Type </th>
                            <th> Insurer </th>
                            <th> Policy Name </th>
                            <th> Policy Number </th>
                        </tr>
                    </thead>
                    <tbody>
                    {insuranceRows}
                    </tbody>
                </Table>
                {emergencyContacts &&
                    <Container>
                        <Table style={{fontSize: '0.75rem'}}>
                            <thead>
                                <tr>
                                    <td colSpan={2}> Emergency Contacts </td>
                                </tr>
                            </thead>
                            <tbody>
                                {emergencyContacts.emerContact1 === '' && emergencyContacts.emerContact2 === '' &&
                                    <tr>
                                        No emergency contacts for trip
                                    </tr>
                                }
                                {emergencyContacts.emerContact1 !== '' &&
                                    <tr>
                                        <td>{emergencyContacts.emerContact1}</td>
                                        <td>{emergencyContacts.emerTel1}</td>
                                    </tr>
                                }
                                {emergencyContacts.emerContact2 !== '' &&
                                    <tr>
                                        <td>{emergencyContacts.emerContact2}</td>
                                        <td>{emergencyContacts.emerTel2}</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Container>
                }
            </div>
        )
    }
}

export default InsuranceContacts;