import React, { Component } from 'react';
import { Container } from 'bloomer';
import nameReplace from "../Helpers/NameReplace";

export default class DietaryBlock extends Component {
    render() {
        let { info } = this.props;

        return(
            <Container className="dietaryBlock">
                <Container className="blockHeader">
                    <b>{nameReplace(info.firstName)} {info.familyName}</b> - <small>Age: {info.age}</small>
                </Container>
                {info.dietaryInformation[0] + info.dietaryInformation.substring(1).toLowerCase()}
            </Container>
        )
    }
}