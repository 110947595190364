import React, { Component } from 'react';
import { Container } from 'reactstrap';
import {
    Navbar,
    NavbarBrand,
    Icon,
    NavbarItem
} from "bloomer";
import {bindActionCreators} from "redux";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import {actionCreators} from "../store/Store";
import TripMenu from "./TripMenu";
import { TripStatus } from "../Types/tripStatus";

class PageLayout extends Component {
    constructor(props) {
        super(props);

        let { checkLoginAsync , logoutAsync } = this.props;

        checkLoginAsync().then(loggedIn => {
            if(!loggedIn) {
                logoutAsync();
                window.location = '/login';
            }
        })

    }

    signOut = async () => {
        let {logoutAsync, push} = this.props;

        await logoutAsync();
        push('/login');
    };

    render() {
        let { children, tripId, roles, push, page, tripTitle, tripType, user, userName, tripStatusId } = this.props;

        let loggedIn = localStorage.getItem("loggedIn");
        
        return(
            <div>
                <Navbar>
                    <NavbarBrand>
                        <NavbarItem>
                            <Icon isSize="medium" className="fa fa-globe fa-2x" onClick={() => {push('/')}} />
                        </NavbarItem>
                        { loggedIn && <NavbarItem>
                            <small>Logged in as <b>{userName}</b></small>
                        </NavbarItem> }
                        { loggedIn && <NavbarItem>
                            <Icon isSize="medium" className="fa fa-sign-out fa-2x" onClick={this.signOut}/>
                        </NavbarItem> }
                    </NavbarBrand>
                </Navbar>
                <Container>
                    <small className="tripBlockStatus">{TripStatus[tripStatusId]}</small>
                    <h1 className="page-title">{tripTitle}</h1>
                    <TripMenu tripType={tripType} tripId={tripId} roles={roles} page={page} push={push} />
                    {children}
                </Container>
            </div>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(PageLayout);
