import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import { Container } from "bloomer";
import {get} from "axios";
import moment from 'moment';
import Collapsible from 'react-collapsible';
import FloorTable from "../components/FloorTable";

class Accommodation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resortRoomAllocation: [],
        }
    }

    async componentDidMount() {
        let {id: tripId} = this.props.match.params;

        let { setTripIdAsync } = this.props;

        setTripIdAsync(tripId);

        let options = {
            params: {
                tripId,
            }
        };

        let res = await get('/api/Accommodation/GetTripRooms', options);

        this.setState({
            resortRoomAllocation: await res.data,
        })
    }

    render() {
        let { resortRoomAllocation } = this.state;

        if(resortRoomAllocation.length <= 0) {
            return (
                <Container className="accommodationOverview">
                    No accommodation assigned to this trip.
                </Container>
            )
        }
        
        return (
            <Container className="accommodationOverview">
                <h3 className="sectionTitle">Accommodation & Rooming</h3>
                {resortRoomAllocation.map((resort, i) => {
                        let nights = moment(resort.departureDate).diff(resort.arrivalDate, 'days');
                    
                        return (
                            <Container className="resortBlock" key={`group-${i}`}>
                                <span className="resort" style={{fontWeight: 'bold'}}>{resort.accommodationName}</span>
                                <br/>
                                <small>
                                    {moment(resort.arrivalDate).format("DD MMM YYYY")} to {moment(resort.departureDate).format("DD MMM YYYY")} |
                                    {' '}
                                    <i>{
                                        nights > 1 ? `${nights} nights` : '1 night'
                                    }</i>
                                </small>
                                <span className="FloorPanels">
                                {resort.floors && resort.floors.map((floor, i) => (
                                    <Collapsible key={`group-${i}-floor${i}`} trigger={`Floor ${floor.floor}`}>
                                        <FloorTable rooms={floor.rooms}/>
                                    </Collapsible>
                                ))}
                            </span>
                            </Container>
                        )
                    }
                )}
            </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(Accommodation);