import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import { get } from 'axios';
import { Link } from "react-router-dom";
import { Container, Title } from 'bloomer';
import { ReactComponent as BootLogo } from '../Icons/ski-boots.svg';
import { ReactComponent as BoardBootsLogo } from "../Icons/snowboard-boots.svg";
import { ReactComponent as SkiLogo } from '../Icons/ski.svg';
import { ReactComponent as BoardLogo } from '../Icons/snowboard.svg';
import { ReactComponent as HelmetLogo } from '../Icons/helmet.svg';
import { ReactComponent as BoyLogo } from '../Icons/boy.svg';
import { ReactComponent as GirlLogo } from '../Icons/girl.svg';
import nameReplace from "../Helpers/NameReplace";

class Participant extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            participant: null,
            abilityLevel: null,
        }
    }

    async componentDidMount() {
        let { id }  = this.props.match.params;
        let { tripId, setTripIdAsync } = this.props;

        let options = {
            params: {
                participantId: id,
            }
        };

        let res = await get('/api/People/GetParticipantDetails', options);
        let participant = await res.data;

        if(tripId !== participant.tripId) setTripIdAsync(participant.tripId);

        this.setState( {
            participant,
            abilityLevel: participant.mountainBikeAbility ? `MB${participant.mountainBikeAbility}` : `S${participant.ability}`,
        });
    }

    render() {
        let { tripId, tripType } = this.props;
        let { participant } = this.state;

        if(!participant) return null;
        
        let abilityLevel;
        
        return (
            <Container className="peopleOverview">
                <span className="button buttonLink">
                    <Link to={`/participants/${tripId}`}>Back to full list</Link>
                </span> <br/>
                <Title isSize={5} className="participantDetailName">
                    {`${nameReplace(participant.firstName)} `}
                    {participant.familyName}
                    <span className="participantAge"> - {participant.age} </span>
                </Title>
                <br/>
                <span className="participantGender">
                    {participant.gender === "M" &&
                        <BoyLogo className="genderIcon" width="80px" height="80px"/>
                    }
                    {participant.gender === "F" &&
                        <GirlLogo className="genderIcon" width="80px" height="80px"/>
                    }
                </span>
                <div className="participantDetails">
                    {participant.mountainBikeAbility &&
                        <>
                            Ability Level:
                            <span className="participantDetail">
                                {` MB${participant.mountainBikeAbility}`}
                            </span><br/>
                        </>
                    }
                    {!participant.mountainBikeAbility &&
                        <>
                            Ability Level:
                            <span className="participantDetail">
                                { participant.skiBoard === "SKI" && ` S${participant.ability}`}
                                { participant.skiBoard === "BOARD" && ` B${participant.ability}`}
                            </span><br/>
                        </>
                    }
                    {tripType !== 'Multi-activity' &&
                        <>
                            Height: <span className="participantDetail">{participant.height}</span><br/>
                            Shoe Size: <span className="participantDetail">{participant.shoeSize}</span><br/>
                        </>
                    }
                </div>
                <br/>
                <div className="participantDetailsIcons">
                    {
                        participant.skiBoard === "SKI" &&
                            <BootLogo className={participant.bootRental === "Y" ? "participantIcon active" : "participantIcon inactive"}/>
                    }
                    {
                        participant.skiBoard == "BOARD" &&
                            <BoardBootsLogo className={participant.bootRental === "Y" ? "participantIcon active" : "participantIcon inactive"}/>
                    }
                    {
                        participant.skiBoard === "SKI" &&
                            <SkiLogo className={participant.skiBoardRental === 'Y' ? 'participantIcon active' : 'participantIcon inactive'}/>
                    }
                    {
                        participant.skiBoard === "BOARD" &&
                            <BoardLogo className={participant.skiBoardRental === 'Y' ? 'participantIcon active' : 'participantIcon inactive'}/>
                    }
                    {/*<MaskLogo className={participant.bootRental === "Y" ? "participantIcon active" : "participantIcon inactive"}/>*/}
                    <HelmetLogo className={participant.helmetRental === "Y" ? "participantIcon active" : "participantIcon inactive"}/>
                </div>
                {participant.medicalInformation &&
                <Container className="participantMedicalInfo">
                    <p className="participantMedicalTitle" style={{fontWeight: 'bold'}}>Medical Information</p>
                    <span className="participantMedicalBody">
                        {participant.medicalInformation[0] + participant.medicalInformation.substring(1).toLowerCase()}
                    </span>
                </Container>
                }
                <br/>
                {participant.dietaryInformation &&
                <Container className="participantDietInfo">
                    <p className="participantDietTitle" style={{fontWeight: 'bold'}}>Dietary Information</p>
                    <span className="participantMedicalBody">
                        {participant.dietaryInformation[0] + participant.dietaryInformation.substring(1).toLowerCase()}
                    </span>
                </Container>
                }
            </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(Participant);