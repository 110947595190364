import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import {Modal, ModalBackground, ModalContent, ModalClose, Container, Field, Control, Button} from 'bloomer';

class ParticipantSort extends Component {
    constructor(props) {
        super(props);

        let { familyName, firstName, type } = props.participantSort;

        this.state = {
            type,
            familyName,
            firstName,
        };
    }

    updateSort = () => {
        let { updateParticipantSort, toggleModal } = this.props;

        updateParticipantSort(this.state);
        toggleModal('sortModal');

    };

    updateSortMethod = (field, sort) => {
        let currentSort = this.state[field];

        this.setState({
            [field]: currentSort === sort ? null : sort,
        })
    };

    render() {
        let { familyName, firstName, type } = this.state;
        let { modalOpen, toggleModal } = this.props;

        return (
            <Modal isActive={modalOpen} className="searchModal">
                <ModalContent>
                    <Container>
                        <h3 className="sectionTitle">Sort Participants</h3>
                        <Container className="sortMethods">
                            <Container className={familyName === 'familyName' ? 'sortMethod active' : 'sortMethod'}
                                       onClick={() => this.updateSortMethod('familyName', 'familyName')}>
                                Family Name - A to Z
                            </Container>
                            <Container className={familyName === '-familyName' ? 'sortMethod active' : 'sortMethod'}
                                       onClick={() => this.updateSortMethod('familyName', '-familyName')}>
                                Family Name - Z to A
                            </Container>
                            <Container className={firstName === 'firstName' ? 'sortMethod active' : 'sortMethod'}
                                       onClick={() => this.updateSortMethod('firstName', 'firstName')}>
                                First Name - A to Z
                            </Container>
                            <Container className={firstName === '-firstName' ? 'sortMethod active' : 'sortMethod'}
                                       onClick={() => this.updateSortMethod('firstName', '-firstName')}>
                                First Name - Z to A
                            </Container>
                            <Container className={type === 'type' ? 'sortMethod active' : 'sortMethod'}
                                       onClick={() => this.updateSortMethod('type', 'type')}>
                                Type - A to Z
                            </Container>
                            <Container className={type === '-type' ? 'sortMethod active' : 'sortMethod'}
                                       onClick={() => this.updateSortMethod('type', '-type')}>
                                Type - Z to A
                            </Container>
                        </Container>
                        <Field isGrouped>
                            <Control>
                                <Button className="searchButton" isColor="info" onClick={this.updateSort}> Sort </Button>
                            </Control>
                            <Control>
                                <Button className="cancelSearch" onClick={() => toggleModal('sortModal')}> Cancel </Button>
                            </Control>
                        </Field>
                    </Container>
                </ModalContent>
                <ModalClose style={{ backgroundColor: 'black' }} onClick={() => toggleModal('sortModal')} />
            </Modal>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(ParticipantSort);