import React,  { Component } from 'react'
import { Container } from 'bloomer';
import moment from 'moment';
import Collapsible from "react-collapsible";

export default class Arrival extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { arrival } = this.props;

        let arrivalNumber = arrival.busDriverNo;

        if(!arrivalNumber && arrival.telephoneNums.length > 0) {
            arrivalNumber = arrival.telephoneNums[0]
        }

        return (
            <Container>
                <p className="arrivalDate">
                    <strong>{moment(arrival.arrivalDate).format("DD MMM YYYY")} | {arrival.collectionPoint}</strong>
                </p>
                <p className="arrivalFlightTranNumber">
                    <strong>{arrival.flightTrainNumber} | {moment(arrival.flightTrainTime).format("HH:mm")}</strong>
                </p>
                <p className="arrivalResortTime">
                    Resort Time: <strong>{moment(arrival.resortTime).format("HH:mm")}</strong>
                </p>
                <p className="arrivalProvider">
                    Provider: <strong>{arrival.transportProvider}</strong>
                </p>
                <p className="tripReference">
                    Reference: <strong> {arrival.tripJourneyName}</strong>
                </p>
                {arrival.busDriverName !== "" &&
                <p className="arrivalDriver">
                    Driver: <strong> {arrival.busDriverName} </strong>
                    {arrivalNumber &&
                    <span>
                                    <br/>
                                    Number: <strong>+{arrivalNumber.countryCode} {arrivalNumber.diallingCode.trim()} {arrivalNumber.phoneNumber}</strong>
                                </span>}
                </p>
                }
                <p className="arrivalNotes">
                    Notes: <strong>{arrival.notes}</strong>
                </p>
            </Container>
        )
    }
}