import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import {actionCreators} from "../store/Store";
import { get } from 'axios';
import { participantTypes } from '../Types/participants';
import {
    Container,
    Input,
    Modal,
    ModalContent,
    ModalClose,
    Control,
    Field,
    Label,
    Button, Select
} from "bloomer";
import AutoSuggest from 'react-autosuggest';

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(input, people, name) {
    let { value } = input;

    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
        return [];
    }

    const regex = new RegExp('^' + escapedValue, 'i');

    return people.filter(person => regex.test(person[name]))
}

function renderSuggestion(suggestion) {
    return (
        <span>{suggestion.firstName} {suggestion.familyName}</span>
    )
}

class ParticipantSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            type: '',
            suggestions: [],
        };
    }

    updateValues = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    performSearch = () => {
        let { updateParticipantSearch, toggleModal } = this.props;

        updateParticipantSearch(this.state);
        toggleModal('searchModal');
    };

    async componentDidMount() {
        let { participantFirstName, participantLastName, participantType, participants } = this.props;

        let res = await get('/api/People/GetParticipantTypes');

        let people = participants.map(participant => {
            return {
                firstName: participant.firstName,
                familyName: participant.familyName
            }
        });

        this.setState({
            types:  await res.data,
            firstName: participantFirstName,
            lastName: participantLastName,
            people: people,
            peopleSuggestions: [],
            type: participantType,
        })
    }

    onSuggestionsFetchRequested = (value, people, name) => {
        this.setState({
           suggestions: getSuggestions(value, people, name)
        })
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        })
    };

    getSuggestion = (suggestion, field) => {
        this.setState({
            firstName: suggestion.firstName,
            lastName: suggestion.familyName,
        });

        // Throws an error if you don't return a string even though you set the state above.
        return suggestion[field];
    };

    onFirstNameChange = (event, {newValue}) => {
        this.setState({
            firstName: newValue,
        })
    };

    onFamilyNameChange = (event, {newValue}) => {
        this.setState({
            lastName: newValue,
        })
    };

    clearSearch = () => {
        this.setState({
            firstName: '',
            lastName: '',
            type: '',
        })
    };

    render() {
        let { modalOpen, toggleModal, participants } = this.props;
        let { types, firstName, lastName, type, suggestions } = this.state;

        let people = participants.map(participant => {
            return {
                firstName: participant.firstName,
                familyName: participant.familyName,
            }
        });

        if(!participants) return null;

        let typeOptions = types
            ? types.map((type, i) => <option key={`options-${i}`} value={type}>{participantTypes[type]}</option>)
            : null;

        const firstNameInputProps = {
            placeholder: "",
            value: firstName,
            onChange: this.onFirstNameChange,
            className: 'input'
        };

        const familyNameInputProps = {
            value: lastName,
            onChange: this.onFamilyNameChange,
            className: 'input'
        };

        return (
            <Modal isActive={modalOpen} className='searchModal'>
                <ModalContent>
                    <Container>
                        <h3 className="sectionTitle">Search Participants</h3>
                        <Control>
                            <Button onClick={this.clearSearch}>Clear Search</Button>
                        </Control>
                        <Field>
                            <Label>First Name</Label>
                            <Control>
                            <AutoSuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={(value) => this.onSuggestionsFetchRequested(value, people, 'firstName')}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={(value) => this.getSuggestion(value, 'firstName')}
                                renderSuggestion={renderSuggestion}
                                inputProps={firstNameInputProps}
                            />
                            </Control>
                        </Field>
                        <Field>
                            <Label>Family Name</Label>
                            <Control>
                                <AutoSuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={(value) => this.onSuggestionsFetchRequested(value, people, 'familyName')}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={(value) => this.getSuggestion(value, 'familyName')}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={familyNameInputProps}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Label>Type</Label>
                            <Control>
                                <Select value={type} name='type' onChange={this.updateValues}>
                                    <option value='' />
                                    {typeOptions}
                                </Select>
                            </Control>
                        </Field>
                        <Field isGrouped>
                            <Control>
                                <Button className="searchButton" isColor="info" onClick={this.performSearch}>Search</Button>
                            </Control>
                            <Control>
                                <Button className="cancelSearch" onClick={() => toggleModal('searchModal')}>Cancel</Button>
                            </Control>
                        </Field>
                    </Container>
                </ModalContent>
                <ModalClose style={{ backgroundColor: 'black'}} onClick={() => {toggleModal('searchModal')}}/>
            </Modal>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(ParticipantSearch);