import React, { Component } from 'react';
import { get } from 'axios';
import moment from 'moment';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import { Container } from 'bloomer';
import Collapsible from 'react-collapsible';
import TripResources from "../components/TripResources";
import InsuranceContacts from "../components/InsuranceContacts";
import EquipmentOverview from "../components/EquipmentOverview";
import TripNotes from "../components/TripNotes";
import TripActivities from "../components/TripActivities";
import TripBirthdays from "../components/TripBirthdays";
import PhotoGallery from "../components/PhotoGallery";

class Trip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trip: null,
            tripProgramme: null,
        }
    }

    async componentDidMount() {
        let {id: tripId} = this.props.match.params;

        let { setTripIdAsync } = this.props;

        setTripIdAsync(tripId);

        let options = {
            params: {
                tripId
            }
        };

        let [ tripRes, programmeRes ] = await Promise.all([
            get('/api/Trips/GetTrip', options),
            get('/api/Trips/TripProgramme', options),
        ]);

        let tripInfo = await tripRes.data;
        let programmeInfo = await programmeRes.data;

        this.setState({
            tripInfo: tripInfo,
            tripProgramme: programmeInfo
        })
    }

    render() {
        let { id } = this.props.match.params;
        let { roles, tripType } = this.props;
        let { tripInfo, tripProgramme } = this.state;
        let phoneNum;

        if(!tripInfo) {
            return( null )
        }

        let { trip, resorts } = tripInfo;

        if(trip.contactNumber) {
            phoneNum = `+${trip.contactNumber.countryCode} ${trip.contactNumber.diallingCode} ${trip.contactNumber.phoneNumber}`
        }

        let resortBlock = resorts ? resorts.map(resort => {
            let days = moment(resort.departureDate).diff(moment(resort.arrivalDate), 'days');

            return (
                <p key={resort.resortAccommodationId} className="resortListItem">
                    <b>{resort.resort} - {resort.accommodation}</b> <br/>
                    <small>{moment(resort.arrivalDate).format("DD MMM YYYY")} to {moment(resort.departureDate).format("DD MMM YYYY") + ' | '}
                    <i>{days > 1 ? `${days} nights` : `1 night` }</i></small>
                </p>
            )
        }) : null;

        return(
            <Container className="tripOverview">
                <p>Client Type: <b>{trip.clientType}</b></p>
                <p>Trip Type: <b>{trip.type}</b></p>
                <p>Contact: <b>{trip.contactFirstName} {trip.contactLastName} { phoneNum ? `| ${phoneNum}` : ''}</b></p>

                <Collapsible trigger="Accommodation">
                    {resortBlock}
                </Collapsible>

                { roles.includes("MobileResources") &&
                    <Collapsible trigger="Resources">
                        <TripResources tripId={id} />
                    </Collapsible>
                }

                {tripType !== 'Room & Board' &&
                    <Collapsible trigger="Insurance & Emergency Contacts">
                        <InsuranceContacts tripId={id}/>
                    </Collapsible>
                }
                {tripType !== 'Room & Board' && (
                    <div>
                        {tripType !== 'Multi-activity' &&(
                            <Collapsible trigger="Equipment">
                                <EquipmentOverview programme={tripProgramme} />
                            </Collapsible>
                        )}
                        <Collapsible trigger="Notes">
                            <TripNotes programme={tripProgramme} />
                        </Collapsible>
                        <Collapsible trigger="Activities">
                            <TripActivities tripId={id} />
                        </Collapsible>
                        <Collapsible trigger="Birthdays">
                            <TripBirthdays tripId={id} />
                        </Collapsible>
                        <Collapsible trigger="Photo Gallery">
                            <PhotoGallery tripId={id} />
                        </Collapsible>
                    </div>
                )}

            </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(Trip);