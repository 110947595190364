import { get, post } from 'axios';
import moment from 'moment';

const logInSuccess = 'LOG_IN_SUCCESS';
const logInFail = 'LOG_IN_FAIL';
const logOut = 'LOG_OUT';
const setTripId = 'SET_TRIP_ID';
const updateParticipantFilter = 'UPDATE_PARTICIPANT_FILTER';
const updateParticipantName = 'UPDATE_PARTICIPANT_NAME';
const setTripDate = 'SET_TRIP_DATE';
const getTrips = 'GET_TRIPS';
const getResorts = 'GET_RESORTS';
const getClients = 'GET_CLIENTS';
const selectClient = 'SELECT_CLIENT';
const selectAccommodation = 'SELECT_ACCOMMODATION';
const getContacts = 'GET_CONTACTS';
const getAccommodation = 'GET_ACCOMMODATION';
const selectContact = 'SELECT_CONTACT';
const selectResort = 'SELECT_RESORT';
const updateParticipantSearch = 'UPDATE_PARTICIPANT_SEARCH';
const updateParticipantSort = 'UPDATE_PARTICIPANT_SORT';
const getWeekTripsAction = 'GET_WEEK_TRIPS';
const clearSearch =  'CLEAR_SEARCH';
const setName = 'SET_NAME';

let user = localStorage.getItem("user");
let roles = localStorage.getItem("roles");

const initialState = {
    loggedIn: localStorage.getItem("loggedIn"),
    userName: null,
    user: user ? JSON.parse(user) : null,
    roles: roles ? JSON.parse(roles) : null,
    tripId: 0,
    tripType: 0,
    tripStatusId: 0,
    tripTitle: '',
    participantFilter: '',
    participantFirstName: '',
    participantLastName: '',
    participantType: '',
    tripFrom: null,
    tripTo: null,
    trips: null,
    clientList: null,
    selectedClientId: null,
    contactList: null,
    selectedContactId: null,
    resortList: null,
    selectedResortId: null,
    accommodationList: null,
    selectedAccommodationId: null,
    tripSearchActive: false,
    participantSort : {
        type: null,
        familyName: null,
        firstName: null,
    }
};

const loginAsync = function(username, password)  {

    return(dispatch) => post('/api/authentication/Login', {username, password}).then(res => {
        let loginResult = res.data;

        localStorage.setItem('name', loginResult.name);

        if(loginResult.success) {
            dispatch({type: logInSuccess, loginResult})
        } else {
            dispatch({type: logInFail })
        }
    });
};

const CheckLoginAsync = function() {
    return(dispatch) => get('/api/authentication/IsAuthenticated').then(res => {
       let loggedIn = res.data;
       let name = localStorage.getItem('name');

       dispatch({
           type: setName,
           name
       });

       return Promise.resolve(loggedIn);
    })
};

const LogoutAsync = function logOutAsync() {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("roles");
    localStorage.removeItem("user");

    return async (dispatch) => {
        await get('/api/authentication/Logout');

        dispatch({type: logOut});
    }
};

const setTripIdAsync = function SetTripId(tripId) {
    return async(dispatch) => {
        let options = {
            params: {
                tripId
            }
        };

        get('/api/Trips/GetTrip', options).then(res => {
            let { trip } = res.data;
            
            let dateString = `${moment(trip.startDate).format("DD.MM.YYYY")} - ${moment(trip.endDate).format("DD.MM.YYYY")}`;

            let title = trip.journeyNotes
                ? `${trip.bookerName ? trip.bookerName : trip.roomBoardName} | ${trip.journeyNotes} | ${dateString}`
                : `${trip.bookerName ? trip.bookerName : trip.roomBoardName} | ${dateString}`;

            dispatch({
                type: setTripId,
                tripId,
                tripTitle: title,
                startDate: trip.startDate,
                endDate: trip.endDate,
                tripType: trip.type,
                tripStatusId: trip.tripStatusId,
            })
        })
    }
};

function getWeekTrips(startDate) {
    return (dispatch) => {
        let options = {
            params: {
                startDate: startDate.toISOString(),
            }
        };

        get('/api/trips/GetWeekTrips', options).then(res => {
            dispatch({
                type: getWeekTripsAction,
                trips: res.data,
            })
        })
    }
}

function setTripDateAsync(startDate, endDate){
    return (dispatch, getState) => {

        let { selectedClientId, selectedContactId, selectedResortId, selectedAccommodationId } = getState().store;

        let options = {
            params: {
                startDate: startDate ? startDate.toISOString() : null,
                endDate: endDate ? endDate.toISOString() : null,
                clientId: selectedClientId,
                contactId: selectedContactId,
                resortId: selectedResortId,
                accommodationId: selectedAccommodationId,
            }
        };

        get(`/api/trips/GetTrips`, options).then(res => {
            dispatch({
                type: setTripDate,
                trips: res.data,
                startDate,
                endDate
            })
        });
    }
}

function getClientsAsync() {
    return dispatch => {
        get('api/people/GetClients').then(res => {
            dispatch({
                type: getClients,
                clientList: res.data,
            })
        })
    }
}

function getContactsAsync() {
    return dispatch => {
        get('api/people/GetContacts').then(res => {
            dispatch({
                type: getContacts,
                contactList: res.data,
            })
        })
    }
}

function getResortsAsync() {
    return dispatch => {
        get('api/accommodation/GetResorts').then(res => {
            dispatch({
                type: getResorts,
                resortList: res.data,
            })
        })
    }
}

function getAccommodationAsync() {
    return dispatch => {
        get('api/accommodation/GetAccommodations').then(res => {
            dispatch({
                type: getAccommodation,
                accommodationList: res.data,
            })
        })
    }
}

export const actionCreators = {
    loginAsync: loginAsync,
    checkLoginAsync: CheckLoginAsync,
    logoutAsync: LogoutAsync,
    setTripIdAsync: setTripIdAsync,
    clearSearch: () => ({
        type: clearSearch
    }),
    updateParticipantFilter: filter => ({
        type: updateParticipantFilter,
        filter
    }),
    getTrips: () => ({
        type: getTrips,
    }),
    updateParticipantSearch: values => ({
        type: updateParticipantSearch,
        values
    }),
    setTripDateAsync:  setTripDateAsync,
    getWeekTrips: getWeekTrips,
    getClientsAsync: getClientsAsync,
    getAccommodationAsync:getAccommodationAsync,
    selectClient: selectedClientId => ({
        type: selectClient,
        selectedClientId
    }),
    getContactsAsync:  getContactsAsync,
    getResortsAsync: getResortsAsync,
    selectContact: selectedContactId => ({
        type: selectContact,
        selectedContactId,
    }),
    selectResort: selectedResortId => ({
        type: selectResort,
       selectedResortId,
    }),
    selectAccommodation: selectedAccommodationId => ({
        type: selectAccommodation,
        selectedAccommodationId,
    }),
    updateParticipantSort: sort => ({
        type: updateParticipantSort,
        sort
    })
};

export const reducer = (state, action) => {
    const currentState = state || initialState;

    if (action.type === setName) {
        return {
            ...currentState,
            userName: action.name,
        }
    }

    if(action.type === logInSuccess) {

        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("user", JSON.stringify(action.loginResult.user));
        localStorage.setItem("roles", JSON.stringify(action.loginResult.roles));

       return {
           ...currentState,
           loggedIn: true,
           userName: action.loginResult.name,
           user: action.loginResult.user,
           roles: action.loginResult.roles
       }
    }

    if (action.type === clearSearch) {
        return {
            ...currentState,
            selectedAccommodationId: null,
            selectedResortId: null,
            selectedContactId: null,
            selectedClientId: null,
            tripSearchActive: false,
            tripFrom: null,
            tripTo: null,
        }
    }

    if ( action.type === updateParticipantSort) {
        let { sort } = action;

        return {
            ...currentState,
            participantSort: sort,
        }
    }

    if (action.type === updateParticipantSearch) {
        let { firstName, lastName, type } = action.values;

        return {
            ...currentState,
            participantFirstName: firstName,
            participantLastName: lastName,
            participantType: type,
        }
    }

    if (action.type === selectClient) {
        let { selectedClientId } = action;

        return {
            ...currentState,
            selectedClientId
        }
    }

    if (action.type === selectAccommodation) {
        let { selectedAccommodationId } = action;

        return {
            ...currentState,
            selectedAccommodationId,
        }
    }

    if (action.type === selectResort) {
        let { selectedResortId } = action;

        return {
            ...currentState,
            selectedResortId,
        }
    }

    if (action.type === selectContact) {
        let { selectedContactId } = action;

        return {
            ...currentState,
            selectedContactId
        }
    }

    if (action.type === getClients) {
        let { clientList } = action;

        return {
            ...currentState,
            clientList,
        }
    }

    if (action.type === getAccommodation) {
        let { accommodationList } = action;

        return {
            ...currentState,
            accommodationList,
        }
    }

    if (action.type === getContacts) {
        let { contactList } = action;

        return {
            ...currentState,
            contactList,
        }
    }

    if (action.type === getResorts) {
        let { resortList } = action;

        return {
            ...currentState,
            resortList,
        }
    }

    if (action.type === getWeekTripsAction) {
        let { trips } = action;

        return {
            ...currentState,
            trips,
        }
    }

    if (action.type === setTripDate) {
        let { endDate: tripTo, startDate: tripFrom , trips } = action;

        return {
            ...currentState,
            tripTo,
            tripFrom,
            trips,
            tripSearchActive: true,
        }
    }

    if(action.type === updateParticipantName) {
        let { name, value } = action;

        return {
            ...currentState,
            [name]: value,
        }
    }

    if(action.type === updateParticipantFilter) {
        let { filter } = action;

        return {
            ...currentState,
            participantFilter: filter,
        }
    }

    if(action.type === logInFail) {
        return {
            ...currentState,
            loggedIn: false
        }
    }

    if(action.type === logOut) {
        return initialState;
    }

    if(action.type === setTripId) {
        const { tripId, tripTitle, startDate, endDate, tripType, tripStatusId } = action;

        return {
            ...currentState,
            tripId,
            tripTitle,
            startDate,
            endDate,
            tripType,
            tripStatusId,
        }
    }

    return currentState;
};

