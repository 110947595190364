import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators } from "redux";
import{ connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import TripBlock from '../components/TripBlock';
import { Title, Icon } from 'bloomer'
import { Swipeable } from "react-swipeable";
import TripSearch from "../components/TripSearch";

const pageRoles = {
    'trip': 'MobileTripInformation',
    'medical' : 'MobileMedicalInformation',
    'accommodation': 'MobileAccomInformation',
    'people': 'MobilePeopleInformation',
    'dietary': 'MobileDietaryInformation',
    'transport': 'MobileTransportInformation',
}

class Home extends Component {
    constructor(props) {
        super(props);

        let { checkLoginAsync , logoutAsync } = this.props;

        checkLoginAsync().then(loggedIn => {
            if(!loggedIn) {
                logoutAsync();
            }
        });

        this.state = {
            trips: null,
            searchOpen: false,
            startDate: null,
        };
    }

    async componentDidMount() {
        let { tripFrom, tripTo, setTripDateAsync, tripSearchActive, getWeekTrips } = this.props;

        let today = moment();
        
        if (tripSearchActive) {
            setTripDateAsync(tripFrom, tripTo);
        } else {
            this.setState({
                startDate: today,
            });
            
            getWeekTrips(today);
        }
    }

    toggleModal = () => {
        this.setState({
            searchOpen: !this.state.searchOpen
        })
    };

    nextWeek = () => {
        let { getWeekTrips } = this.props;
        let { startDate } = this.state;

        let newStartDate = startDate.clone().add(7, "d");

        this.setState({
            startDate: newStartDate
        });

        getWeekTrips(newStartDate);
    };

    previousWeek = () => {
        let { getWeekTrips } = this.props;
        let { startDate } = this.state;

        let newStartDate = startDate.clone().add(-7, "d");

        this.setState({
            startDate: newStartDate
        });

        getWeekTrips(newStartDate);
    };

    gotoTrip = (tripId) => {
        let { setTripIdAsync, roles, history } = this.props;

        setTripIdAsync(tripId);
        
        let filteredRoles = roles.filter(role => role !== 'MobileAccess' && role !== 'MobileResources');
        
        let pages = Object.keys(pageRoles);
        let firstPage;
        
        for (let page of pages) {
            if(filteredRoles.includes(pageRoles[page])) {
                firstPage = page;
                break;
            }
        }
        
        history.push(`/${firstPage}/${tripId}`);
        
    };

    render() {
        let { trips, tripSearchActive } = this.props;
        let { searchOpen, startDate  } = this.state;

        // if( tripFrom === null || tripTo === null) {
        //     return null;
        // }

        let blocks;

        if(trips != null )
        {
            blocks = trips.map(trip => {
                let booker = trip.booker;
                
                return  (
                    <TripBlock key={trip.tripId} booker={booker ? booker.bookerName : trip.roomBoard.roomBoardName}
                               notes={trip.notes} resorts={trip.resorts}
                               dateFrom={trip.startDate} dateTo={trip.endDate}
                               nights={trip.nights} gotoTrip={() => this.gotoTrip(trip.tripId)}
                               tripId={trip.tripId} type={trip.type} statusId={trip.tripStatus}
                    />
                )
            })
        }

        return(
            <Swipeable onSwipedLeft={() => {this.nextWeek()}} onSwipedRight={() => {this.previousWeek()}}>
                <Title className="tripsListTitle" isSize={3}>Trips
                    <button type="button" className="button is-outlined" onClick={this.toggleModal}>Search Trips</button>
                </Title>
                {!tripSearchActive &&
                    <p className="dateRange">
                        <Icon isSize="medium" className="fad fa-caret-square-left" onClick={this.previousWeek}/>
                        <span className="date">
                            {startDate && startDate.format("DD MMM YYYY")} to {startDate && startDate.clone().add(7, 'd').format('DD MMM YYYY')}
                        </span>
                        <Icon isSize="medium" className="fad fa-caret-square-right" onClick={this.nextWeek}/>
                    </p>
                }
                { blocks }
                <TripSearch searchOpen={searchOpen} toggleModal={this.toggleModal} />
            </Swipeable>
        )
    }
}

const mapStateToProps = (state) => {
    let { store } = state;
    let { tripFrom, tripTo, trips, roles, tripSearchActive } = store;

    return {
        tripFrom,
        tripTo,
        trips,
        roles,
        tripSearchActive,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch)
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);