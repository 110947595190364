import React, { Component } from 'react';
import { Table } from 'bloomer';
import Collapsible from 'react-collapsible';
import { get } from 'axios';
import moment from 'moment';

export default class Excursions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            excursions: [],
        }
    }

    async componentDidMount() {
        let { tripId } = this.props;

        let options = {
            params: {
                tripId
            }
        };

        let res = await get('/api/Trips/GetTripExcursions', options);

        this.setState({
            excursions: await res.data,
        })
    }

    render(){
        let { excursions } = this.state;

        let excursionRows = excursions.map((n, i) => {
            let { journeyLeg } = n;
            let driverNo = journeyLeg.busDriverNo;

            if(!driverNo) {
                driverNo = journeyLeg.telephoneNums[0];
            }

            return (
                <Collapsible trigger={`${journeyLeg.tripJourneyName}`}
                             className="excursionDetails" key={`excursion-${i}`}>
                    <p className="excursionDate">
                        Date: <strong>{moment(journeyLeg.excrDate).format("DD MMM YYYY")}</strong>
                    </p>
                    <p className="excursionDeparture">
                        Departure: <strong>{moment(journeyLeg.departureDate).format("HH:mm")}</strong>
                    </p>
                    <p className="excursionReturn">
                        Return: <strong>{moment(journeyLeg.arrivalDate).format("HH:mm")}</strong>
                    </p>
                    <p className="excursionDriver">
                        Driver: <strong>{journeyLeg.busDriverName}</strong>
                    </p>
                    <p className="excursionDriverNumber">
                        Number:
                        <strong>{driverNo && `+${driverNo.countryCode}(${driverNo.diallingCode.trim()}) ${driverNo.phoneNumber}`}</strong>
                    </p>
                    <p className="excursionItinerary">
                        Itinerary: <strong>{journeyLeg.excrItinerary}</strong>
                    </p>
                    <p className="excursionProvider">
                        Provider: <strong>{journeyLeg.provider}</strong>
                    </p>
                    <p className="excursionNotes">
                        Notes: <strong>{journeyLeg.notes}</strong>
                    </p>
                </Collapsible>
            )
        });

        return (
            <div className="excursionTable">
                {excursionRows}
            </div>
        );
    }
}