import React, { Component } from 'react';
import { Container, Table } from 'bloomer';
import moment from 'moment';
import { get } from 'axios';

class TripBirthdays extends Component {
    constructor(props) {
        super(props);
        
        this.state= {
            birthdays: null,
            loading: true,
        }
    }
    
    async componentDidMount() {
        let { tripId } = this.props;
        
        let options = { 
            params: {
                tripId
            }
        };
        
        let res = await get('/api/Trips/GetTripBirthdays', options);
        let birthdays = await res.data;
        
        this.setState({
            birthdays,
        })
    }
    
    render() {
        let { birthdays, loading } = this.state;
        
        if(!birthdays) {
            return (
                <Container>
                    {loading ? 'Loading Birthdays' : 'Trip has no Birthdays'}
                </Container>
            )
        }
        
        let birthdayRows = birthdays.map((n, i) => {
            return (
                <tr key={`birthday-${i}`}>
                    <td>{n.name}</td>
                    <td>{moment(n.birthdayDate).format("DD MMM YYYY")}</td>
                    <td>{n.notes}</td>
                </tr>
            )
        });
        
        return (
            <Container>
                <Table style={{fontSize: '0.7rem'}}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                    {birthdayRows}
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default TripBirthdays;