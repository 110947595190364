export let staffTypes = {
    'Ski counsellor': 'SC',
    'Snowboard counsellor': 'SBC',
    'Ski/snowboard counsellor': 'SSBC',
    'Healthcare counsellor': 'HC',
    'Healthcare and ski counsellor': 'HSC',
    'Healthcare and snowboard counsellor': 'HSBC',
    'Healthcare and ski/snowboard counsellor': 'HSSBC',
    'Multi-activity counsellor': 'MAC',
    'Senior programme manager': 'SPM',
    'Programme manager': 'PM',
    'Leader': 'LD',
    'Chef': 'CH',
    'Assistant chef': 'ACH',
    'Domestic staff': 'DS',
    'Driver/handyman': 'DR',
    'Ski Instructor': 'SI',
};