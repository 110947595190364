import React, { Component } from 'react';
import { Container } from 'bloomer';
import moment from 'moment';
import Collapsible from "react-collapsible";

export default class Departure extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { departure } = this.props;

        let departureNumber = departure.busDriverNo;

        if(!departureNumber && departure.telephoneNums.length > 0) {
            departureNumber = departure.telephoneNums[0];
        }

        return (
            <Container>
                <p className="departureDate">
                    <strong>{moment(departure.departureDate).format("DD MMM YYYY")} | {departure.collectionPoint}</strong>
                </p>
                <p className="departureFlightTrainNumber">
                    <strong>{departure.flightTrainNumber} | {moment(departure.flightTrainTime).format("HH:mm")}</strong>
                </p>
                <p className="departureResortTime">
                    Resort Time: <strong>{moment(departure.resortTime).format("HH:mm")}</strong>
                </p>
                <p className="departureProvider">
                    Provider: <strong>{departure.transportProvider}</strong>
                </p>
                <p className="tripReference">
                    Reference: {departure.tripJourneyName}
                </p>
                {departure.busDriverName !== "" &&
                    <p className="departureDriver">
                        Driver: <strong> {departure.busDriverName} </strong>
                        {departureNumber &&
                            <span>
                                <br/>
                                Number: <strong>+{departureNumber.countryCode} {departureNumber.diallingCode.trim()} {departureNumber.phoneNumber}</strong>
                            </span>
                        }
                    </p>
                }
                <p className="departureNotes">
                    Notes: <strong>{departure.notes}</strong>
                </p>
            </Container>
        )
    }
}