import React, { Component } from 'react';
import { Container } from 'bloomer';
import nameReplace from "../Helpers/NameReplace";

const participantTypes = {
    'CHILD | STUDENT | 16+': 'C16+',
    'CHILD | STUDENT | <16': 'C<16',
    'ADULT | CHAPERONE OR OTHER | PAYING': 'ACOP',
    'CHILD | OF CHAPERONE | PARTICIPATING': 'CCP',
    'CHILD | OF CHAPERONE | NON-PARTICIPATING': 'CCNP',
    'ADULT | CHAPERONE | FREE PLACE': 'ACFP',
    'ADULT | BUS DRIVER': 'BD'
};

export default class MedicalBlock extends Component {
    render() {
        let { info } = this.props;

        let gender;
        
        if(info.gender === 'M') {
            gender = 'Male';
        } else if (info.gender === 'F') {
            gender = 'Female';
        } else {
            gender = 'Not Set';
        }
        
        return(
            <Container className="medicalBlock">
                <Container className="blockHeader">
                    <b>{nameReplace(info.firstName)} {info.familyName}</b> - <small>{participantTypes[info.type]}</small>
                </Container>
                <p className="medicalGender">
                    Gender:
                    <b> { gender }</b>
                </p>
                <p className="medicalAge">
                    Age: <b>{info.age}</b>
                </p>
                <p className="medicalNotes">
                    {info.medicalInformation[0] + info.medicalInformation.substring(1).toLowerCase()}
                </p>
            </Container>
        );
    }
}