import React, { Component } from 'react';
import { get } from 'axios';
import { Container } from 'bloomer';

class PhotoGallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoGallery: null,
            loading: true,
        }
    }

    async componentDidMount() {
        let { tripId } = this.props;

        let options = {
            params: {
                tripId
            }
        };

        let res = await get('/api/trips/TripPhotoGallery', options);

        this.setState({
            photoGallery: await res.data
        })
    }

    render() {
        let { photoGallery, loading } = this.state;

        if(!photoGallery) {
            return (
                <Container>
                    {loading ? 'Loading data' : 'No data found' }
                </Container>
            )
        }

        return (
            <Container>
                User Id: <span style={{fontWeight: 'bold'}}>{photoGallery.userId}</span> <br/>
                Password: <span style={{fontWeight: 'bold'}}>{photoGallery.password}</span>
            </Container>
        )
    }
}

export default PhotoGallery;