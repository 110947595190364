import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import { Container } from 'bloomer';
import { Link } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import RoomBoardSummary from "../components/RoomBoardSummary";
import MultiActivitySummary from "../components/MultiActivitySummary";
import ParticipantSummary from "../components/ParticipantSummary";
import AbilitySummary from '../components/Abilities';

class PeopleInfo extends Component {
    componentDidMount() {
        let { id } = this.props.match.params;
        let { tripId, setTripIdAsync } = this.props;

        if(id !== tripId) setTripIdAsync(id);
    }

    render() {
        let { id } = this.props.match.params;
        let { tripType } = this.props;

        return (
            <Container className="peopleOverview">
                {tripType !== 'Room & Board' &&
                    <span>
                        <Link to={`/participants/${id}`} className="button buttonLink">View Participants</Link>
                        <Link to={`/stafflist/${id}`}  style={{float: 'right'}} className="button buttonLink">View Staff</Link>
                    </span>
                }
                <Collapsible trigger="Participant Summary">
                    {tripType !== 'Room & Board'
                        ? <ParticipantSummary tripId={id}/>
                        : <RoomBoardSummary tripId={id} />
                    }
                </Collapsible>
                {tripType !== 'Room & Board' && tripType !== 'Multi-activity'
                    && <AbilitySummary tripId={id}/>
                }
                {tripType === 'Multi-activity'
                    && <MultiActivitySummary tripId={id} />
                }
            </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(PeopleInfo);