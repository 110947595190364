import React, { Component } from 'react';
import { Container } from 'bloomer';
import moment from 'moment';

let clothingRental = {
    0: "None",
    1: "All",
    2: "Some",
};

let chaperoneRental = {
    0: "None",
    1: "Standard",
    2: "Top",
};

class EquipmentOverview extends Component {
    render() {
        let { programme } = this.props;

        return(
            <Container>
                Helmet Rental: <span style={{fontWeight: 'bold'}}>{programme.helmet ? 'Yes' : 'No'}</span> <br/>
                Clothing Rental: <span style={{fontWeight: 'bold'}}>{clothingRental[programme.clothing]}</span><br/>
                Chaperone Equipment: <span style={{fontWeight: 'bold'}}>{chaperoneRental[programme.chaperoneEquipment]}</span><br/>
                Collect Date: <span style={{fontWeight: 'bold'}}>{moment(programme.collectDate).format("DD MMM YYYY")}</span><br/>
                Return Date: <span style={{fontWeight: 'bold'}}>{moment(programme.returnDate).format("DD MMM YYYY")}</span><br/>
                Rental Days: <span style={{fontWeight: 'bold'}}>{programme.noRentalDays}</span>
            </Container>
        )
    }
}

export default EquipmentOverview;