import React, { Component } from 'react';
import moment from 'moment';
import { Container } from "bloomer";
import 'flag-icon-css/sass/flag-icon.scss';
import { staffTypes } from '../Types/staff';
import * as getCountryISO2 from 'country-iso-3-to-2';

class StaffBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    goToGroup = (id) => {
        let { push, tripId } = this.props;

        push(`/groups/${tripId}/${id}`);
    };

    render() {
        let { staffMember } = this.props;
        let iso2code;

        if(staffMember.staffMember.individual.nationality) {
            let { countryIsocode } = staffMember.staffMember.individual.nationality;
            iso2code = getCountryISO2(countryIsocode.trim());
        }

        return (
            <Container className='staffBlock' onClick={() => {this.goToGroup(staffMember.staffMember.individualId)}}>
                <span className='staffRole'>{staffTypes[staffMember.staffRole.roleDesc] + ' - '}</span>
                <b>{staffMember.staffMember.individual.firstName} {staffMember.staffMember.individual.lastName.toUpperCase()}</b>
                {staffMember.staffMember.individual.dateOfBirth &&
                    <span className='staffDOB'>
                         {' - ' + moment(staffMember.staffMember.individual.dateOfBirth).format("DD/MM/YY")}
                    </span>
                }
                {' - '}
                <span className='staffGender'>
                    {staffMember.staffMember.individual.gender.trim() === "Male" ? "M" : "F" }
                </span>
                {staffMember.staffMember.individual.nationality &&
                (
                    <span className='staffNationality is-pulled-right'>
                        <span className={`flag-icon flag-icon-${iso2code.toLowerCase()}`} />
                    </span>
                )}
            </Container>
        )
    }
}

export default StaffBlock;