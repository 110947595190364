import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import { Container } from 'bloomer';
import { get } from 'axios';
import MedicalBlock from "../components/MedicalBlock";

class Medical extends Component {
    constructor(props) {
        super(props);

        this.state = {
            medicalInfo: null,
        }
    }

    async componentDidMount() {
        let {id} = this.props.match.params;
        let {tripId, setTripIdAsync} = this.props;

        if (id !== tripId) setTripIdAsync(id);

        let options = {
            params: {
                tripId: id,
            }
        };

        let res = await get('/api/People/GetMedicalInformation', options);

        this.setState({
            medicalInfo: await res.data,
        })
    }

    render() {
        let { medicalInfo } = this.state;

        if(!medicalInfo) return null;

        return (
            <Container className="medicalOverview">
                <h3 className="sectionTitle">Medical Information</h3>
                {medicalInfo.map(medicalInfo => (
                    <MedicalBlock info={medicalInfo} key={medicalInfo.id}/>
                ))}
            </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(Medical);