import React, { Component } from 'react';
import { bindActionCreators} from "redux";
import { connect } from 'react-redux';
import { actionCreators } from "../store/Store";
import {Container, Field, Input, Label, Control, Button,
        Notification} from "bloomer";


class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            showWarning: false,
            isLoading: false,
        };

        const { checkLoginAsync } = this.props;

        checkLoginAsync().then(loggedIn => {
             if(loggedIn) {
                 this.props.history.push('/');
             }
        })
    }

    login = async () => {
        this.setState({
            isLoading: true,
        });

        const { loginAsync } = this.props;
        const {username, password} = this.state;

        await loginAsync(username, password);

        const { loggedIn } = this.props;

        if(loggedIn) {
            const { push } = this.props.history;

            push('/');
        } else {
            this.setState({
                showWarning: true,
                isLoading: false
            })
        }
    };

    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    render() {
        const { username, password, showWarning, isLoading } = this.state;

        return (
            <Container isFluid className="has-">
                {showWarning &&
                    <Notification isColor="warning">
                        Login failed, try again or contact an administrator.
                    </Notification>
                }
                <Field>
                    <Label>Username</Label>
                    <Control>
                        <Input type="text" placeholder="Username" name='username' value={username} onChange={this.updateState} />
                    </Control>
                </Field>
                <Field>
                    <Label>Password</Label>
                    <Control>
                        <Input type="password" placeholder="Password" value={password} name='password' onChange={this.updateState} />
                    </Control>
                </Field>
                <Field>
                    <Button isLoading={isLoading} isColor="info" onClick={this.login}>Login</Button>
                </Field>
            </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(Login);