import React, { Component } from 'react';
import { Container, Table } from 'bloomer';
import moment from 'moment';
import { get } from 'axios';

const lunchTypes = {
    'Lunch (Full)' : 'Full',
    'Lunch (Picnic)' : 'Picnic'
};

const breakfastTypes = {
    'Breakfast (Full)' : 'Full',
    'Breakfast (Continental)': 'Conti'
};

export default class MealsInfo extends Component {
    constructor(props){
        super(props);

        this.state = {
            mealInfo: null,
        }
    }
    async componentDidMount() {
        let { tripId } = this.props;

        let options = {
            params: {
                tripId,
            }
        };

        let res = await get('/api/Meals/GetTripMealInfo', options);

        this.setState({
            mealInfo: await res.data,
        })
    }

    render() {
        let { mealInfo } = this.state;
        let { startDate } = this.props;

        if(!mealInfo) return null;

        let rows = mealInfo.map((n,i) => {
            return(
                <tr key={`day-${i}`}>
                    <td>{moment(startDate).add(n.tripDay, "d").format("DD/MM/YY")}</td>
                    <td>{n.breakfastType ? breakfastTypes[n.breakfastType] : 'N' }</td>
                    <td>{n.lunchType ? lunchTypes[n.lunchType] : 'N'}</td>
                    <td>{n.gouter ? 'Y' : 'N'}</td>
                    <td>{n.dinner ? 'Y' : 'N'}</td>
                </tr>
            )
        });

        return (
            <Container>
                <Table className="mealTable" style={{fontSize: '0.8rem'}} isBordered isNarrow>
                    <thead>
                        <tr>
                            <th> Date </th>
                            <th> B'fast </th>
                            <th> Lunch </th>
                            <th> Goûter </th>
                            <th> Dinner </th>
                        </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </Table>
            </Container>
        )
    }
}