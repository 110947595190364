import React, {Component} from 'react';
import {Container, Table} from 'bloomer';


export default class FloorTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            floor: null,
        }
    }

    render() {
        let {rooms} = this.props;

        let roomRows = rooms.map((r, i) => {

            let capacity = (r.singleBeds || 0) + ((r.doubleBeds || 0) * 2) + ((r.bunkBeds || 0) * 2);

            return (
                <tr key={`row-${i}`}>
                    <td>{r.roomNumber}</td>
                    <td>{r.ensuite ? 'Y' : 'N'}</td>
                    <td>{r.balcony ? 'Y' : 'N'}</td>
                    <td>{r.singleBeds}</td>
                    <td>{r.bunkBeds || 0}</td>
                    <td>{r.doubleBeds || 0}</td>
                    <td>{capacity}</td>
                </tr>
            )
        });

        return (
            <Container>
                <Table className="roomsTable" style={{fontSize: '0.7rem'}} isStriped isBordered>
                    <thead>
                    <tr>
                        <th>Rn</th>
                        <th>E</th>
                        <th>B</th>
                        <th>Sb</th>
                        <th>Bb</th>
                        <th>Db</th>
                        <th>C</th>
                    </tr>
                    </thead>
                    <tbody>
                    {roomRows}
                    </tbody>
                </Table>
                <div className="legend">
                    <strong> Rn</strong> - Room Number,<br/>
                    <strong> E</strong> - Ensuite,<br/>
                    <strong> B</strong> - Balcony,<br/>
                    <strong> Sb</strong> - Single Beds,<br/>
                    <strong> Bb</strong> - Bunk Beds,<br/>
                    <strong> Db</strong> - Double Beds,<br/>
                    <strong> C</strong> - Capacity
                </div>
            </Container>
        )
    }
}