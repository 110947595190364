import React, { Component } from 'react';
import { Container } from 'bloomer';
import nameReplace from "../Helpers/NameReplace";

const participantTypes = {
    'CHILD | STUDENT | 16+': 'C16+',
    'CHILD | STUDENT | <16': 'C<16',
    'ADULT | CHAPERONE OR OTHER | PAYING': 'ACOP',
    'CHILD | OF CHAPERONE | PARTICIPATING': 'CCP',
    'CHILD | OF CHAPERONE | NON-PARTICIPATING': 'CCNP',
    'ADULT | CHAPERONE | FREE PLACE': 'ACFP',
    'ADULT | BUS DRIVER': 'BD'
};


class GroupBlock extends Component {
    goToParticipant = (participantId) => {
        let { push } = this.props;

        push(`/participant/${participantId}`);
    };

    render() {
        let { participant } = this.props;
        let className;
        
        if(participant.skiBoard === 'SKI')
            className = 'participantBlock Ski';
        else if(participant.skiBoard === 'BOARD')
            className = 'participantBlock Board';
        else
            className = 'participantBlock Multi';
        
        return (
            <Container className={className}
                        onClick={() => this.goToParticipant(participant.participantDetailsSkiId)}
            >
                <span className="participantType">{participantTypes[participant.type]}</span>
                <span className="participantName"><b>{nameReplace(participant.firstName)} {participant.familyName}</b></span>
                <span className="groupAge"> {' '}{`- ${participant.age}`}</span>
                <span className="groupGender">{' - '}{participant.gender}</span>
            </Container>
        )
    }
}

export default GroupBlock