import React, { Component } from 'react';
import { Table, Container } from 'bloomer';
import { get } from 'axios';
import ParticipantLegend from "./ParticipantLegend";

const participantTypes = {
    childLessThan16: 'CHILD | STUDENT | <16',
    child16Plus: 'CHILD | STUDENT | 16+',
    CCP: 'CHILD | OF CHAPERONE | PARTICIPATING',
    CCNP: 'CHILD | OF CHAPERONE | NON-PARTICIPATING',
    ACFP: 'ADULT | CHAPERONE | FREE PLACE',
    ACOP: 'ADULT | CHAPERONE OR OTHER | PAYING',
    BD: 'ADULT | BUS DRIVER'
};

class ParticipantSummary extends Component {
    constructor(props){
        super(props);

        this.state = {
            participantSummary: null
        }
    }

    async componentDidMount() {
        let { tripId } = this.props;

        let options = {
            params: {
                tripId
            }
        };

        let res = await get('/api/Trips/ParticipantSummary', options);

        this.setState({
            participantSummary: await res.data
        })
    }

    getTotalRow = () => {
        let { participantSummary } = this.state;

        let male = participantSummary.filter(participant => participant.type.gender === 'M');
        let female = participantSummary.filter(participant => participant.type.gender === 'F');

        let maleTotal = male.reduce((a, b) => a + (b.count), 0);
        let femaleTotal = female.reduce((a,b) => a + (b.count), 0);

        return (
            <tr>
                <td>Total</td>
                <td>{maleTotal}</td>
                <td>{femaleTotal}</td>
                <td>{maleTotal + femaleTotal}</td>
            </tr>
        )
    };

    getParticipantRow = (key) => {
        let { participantSummary } = this.state;

        let type = participantTypes[key];
        let participants = participantSummary.filter(participant => participant.type.type === type);

        let rowKey;

        if(key === 'childLessThan16') {
            rowKey = 'C<16';
        } else if(key === 'child16Plus') {
            rowKey = 'C16+';
        } else {
            rowKey = key;
        }

        if(participants.length === 0) {
            return (
                <tr>
                    <td>{rowKey}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            )
        }

        let male = participants.find(participant => participant.type.gender === 'M');
        let female = participants.find(participant => participant.type.gender === 'F');

        let maleCount = male ? male.count : 0;
        let femaleCount = female ? female.count : 0;

        return (
            <tr>
                <td>{rowKey}</td>
                <td>{maleCount}</td>
                <td>{femaleCount}</td>
                <td>{maleCount + femaleCount}</td>
            </tr>
        );
    };

    render() {
        let { participantSummary } = this.state;

        if(!participantSummary) {
            return (null)
        }

        return (
            <Container>
                <Table isStriped isNarrow isBordered style={{fontSize: '0.8rem'}}>
                    <thead>
                    <tr>
                        <th>Participant Type</th>
                        <th>Male</th>
                        <th>Female</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.getParticipantRow('childLessThan16')}
                        {this.getParticipantRow('child16Plus')}
                        {this.getParticipantRow('CCP')}
                        {this.getParticipantRow('CCNP')}
                        {this.getParticipantRow('ACFP')}
                        {this.getParticipantRow('ACOP')}
                        {this.getParticipantRow('BD')}
                        {this.getTotalRow()}
                    </tbody>
                </Table>
                <ParticipantLegend />
            </Container>
        )
    }
}

export default ParticipantSummary;