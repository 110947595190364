import React, { Component } from 'react';
import { get } from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { actionCreators } from "../store/Store";
import {Container, Input, Icon, Control, Button, Field, Label, Select} from 'bloomer';
import { participantTypes } from '../Types/participants';
import Link from "react-router-dom/es/Link";
import ParticipantSearch from "../components/ParticipantSearch";
import ParticipantSort from "../components/ParticipantSort";
import ParticipantBlock from "../components/ParticipantBlock";

// A Function for multi dimensional sorting on fields in an array of objects.
// Copied from: https://stackoverflow.com/a/6913821
function fieldSorter(fields) {
    let dir = [], i, l = fields.length;
    fields = fields.map(function(o, i) {
        if (o[0] === "-") {
            dir[i] = -1;
            o = o.substring(1);
        } else {
            dir[i] = 1;
        }
        return o;
    });

    return function (a, b) {
        for (i = 0; i < l; i++) {
            let o = fields[i];
            if (a[o] > b[o]) return dir[i];
            if (a[o] < b[o]) return -(dir[i]);
        }
        return 0;
    };
}

class AllParticipants extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            searchModal: false,
            participants: null,
            sortModal: false,
            type: '',
        }

    }

    toggleModal = (modalName) => {
        this.setState({
            [modalName]: !this.state[modalName],
        });
    };


    async componentDidMount() {
        let { id } = this.props.match.params;
        let { tripId, setTripIdAsync } = this.props;

        if(id !== tripId) setTripIdAsync(id);

        let options = {
            params: {
                tripId: id,
            }
        };

        let res = await get('/api/Trips/GetTripParticipants', options);

        this.setState({
            participants: res.data,
        })
    }

    updateParticipantSearch = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    };

    selectType = (e) => {
        this.setState({
            type: e.target.value,
        })
    };

    render() {
        let { id } = this.props.match.params;
        let { filter, participants, searchModal, sortModal, type } = this.state;
        let { participantFilter, participantType, participantSort } = this.props;

        if(!participants) return null;

        let sortFields = Object.values(participantSort).filter(sort => sort !== null);

        let filteredParticipants = type === '' ? participants : participants.filter(participant => participant.type === type);

        filteredParticipants = filteredParticipants
            .filter(participant =>`${participant.firstName.toUpperCase()} ${participant.familyName.toUpperCase()}`.includes(filter.toUpperCase())
            );

        if(participantType) {
            filteredParticipants = filteredParticipants.filter(participant => participant.type === participantType);
        }

        filteredParticipants = filteredParticipants.sort(fieldSorter(sortFields));

        // This looks at the participants and gets a list of unique types so that we can display a selection of only the types included
        // in the trip.
        let uniqueTypes = [...new Set(participants.map(participant => participant.type))];
        
        // Create a set of options for the drop down list.
        let typeOptions = uniqueTypes.map((type, i) => (
            <option key={`type-${i}`} value={type}>{participantTypes[type]}</option>
        ));

        return (
            <Container className="peopleOverview">
                {participantFilter}
                <h3 className="sectionTitle">Trip Participants
                    <span className="button buttonLink"><Link to={`/groups/${id}`}>Groups</Link></span>
                </h3>
                <Container className="filterSearch">
                    {/*<Button isOutlined onClick={() => {this.toggleModal('searchModal')}}>*/}
                    {/*    Search*/}
                    {/*</Button>*/}
                </Container>
                <Field isGrouped style={{padding: '0'}}>
                    <Control style={{width: '52%'}}>
                        <Input placeholder="Search by name" value={filter} name="filter" onChange={this.updateParticipantSearch}/>
                    </Control>
                    <Control>
                        <Select name='type' onChange={this.selectType}>
                            <option value=''>Type</option>
                            {typeOptions}
                        </Select>
                    </Control>
                    <Button isOutlined onClick={() => this.toggleModal('sortModal')}>
                        <i className="fas fa-sort-alt" />
                    </Button>
                </Field>
                {filteredParticipants.length > 0 ? filteredParticipants.map((n,i) => {
                    return (
                        <ParticipantBlock key={`participant-${i}`} participant={n} tripId={id} push={this.props.history.push}/>
                    )
                }) : 'No Results for Search'}
                <ParticipantSearch participants={participants} modalOpen={searchModal} toggleModal={this.toggleModal}/>
                <ParticipantSort modalOpen={sortModal} toggleModal={this.toggleModal} />
            </Container>
        )
    }
}

export default connect(
    state => state.store,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(AllParticipants)